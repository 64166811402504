import React from "react"
import styled from "styled-components"
import { default as DefaultSelect } from "react-select"

const StyledSelectWrapper = styled.div`
  position: relative;
  z-index: 500;
  svg {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    width: 1.6rem;
    pointer-events: none;
  }
`

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    border: "none",
    boxShadow: state.isFocused ? "0px 2px 4px -2px rgba(0, 0, 0, 0.3)" : "none",
    // paddingTop: "2.4rem",
    background: "transparent",
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.2)",
    },
  }),

  menu: (provided, state) => ({
    ...provided,
    borderRadius: 0,
  }),

  multiValueLabel: (provided, state) => ({
    ...provided,
    borderRadius: 0,
  }),

  indicatorsContainer: (provided, state) => ({
    ...provided,
    minWidth: "3.2rem",
    position: "relative",
    alignItems: "end",
    svg: {
      position: "static",
    },
  }),

  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none",
  }),

  multiValueRemove: (provided, state) => ({
    ...provided,
    position: "relative",
    width: "2rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    ":hover": {
      background: "#ccc",
    },
    svg: {
      position: "static",
    },
  }),

  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? "#374649" : "white",
    ":hover": {
      background: "#5f6b6d",
      color: "white",
    },
  }),
}

function Select({
  children,
  size,
  theme,
  style,
  customStyles: modifiedCustomStyles,
  ...props
}) {
  return (
    <StyledSelectWrapper size={size} theme={theme} style={style}>
      <DefaultSelect
        styles={{ ...customStyles, ...modifiedCustomStyles }}
        {...props}
      />
    </StyledSelectWrapper>
  )
}

export { Select }

/* Action types */
import { ApiVehicle } from "app/Devices/types"
import { ActionMap, createActions } from "../../lib/reducer.types"
import { Account } from "./types"

export enum AccountTypes {
  SetAccounts = "Accounts/SetAccounts",
  SetAccount = "Accounts/SetAccount",
  SetAccountVehicles = "Accounts/SetAccountVehicles",
  ClearAccount = "Accounts/ClearAccount",
}

type Actions = {
  [AccountTypes.SetAccounts]: {
    accounts: Account[]
  }
  [AccountTypes.SetAccount]: {
    account: Account
  }
  [AccountTypes.SetAccountVehicles]: {
    accountVehicles: ApiVehicle[]
  }
  [AccountTypes.ClearAccount]: undefined
}

export type AccountsActionMap = ActionMap<Actions>[keyof ActionMap<Actions>]
export const AccountsActions = createActions<Actions>()

import {
  AccountFeatureEnum,
  AccountFeatureMap,
  AccountFunctionsEnum,
  ApiAccountTypeEnum,
} from "app/Account/types"
import { ColumnMap } from "app/DataTable/types"
import format from "date-fns/format"

export const columnMap: ColumnMap = [
  {
    key: "id",
    title: "ID",
  },
  {
    key: "name",
    title: "Navn",
  },
  {
    key: "description",
    title: "Type",
  },
  {
    key: "address",
    title: "Addresse",
  },
  {
    key: "zip",
    title: "Postnr.",
  },
  {
    key: "city",
    title: "By",
  },
  {
    key: "vatNumber",
    title: "CVR",
  },
  {
    key: "phone",
    title: "Hovednummer",
  },
  {
    key: "created",
    title: "Oprettet",
    formatData: (d) => format(new Date(d), "dd-MM-yyyy"),
  },
  {
    key: "VehicleCount",
    title: "Trackere",
  },
  {
    key: "ProbeCount",
    title: "Sporingschips",
  },
]

export const accountType = [
  {
    label: "Kundekonto",
    value: ApiAccountTypeEnum.Client,
  },
  {
    label: "Pilot",
    value: ApiAccountTypeEnum.Pilot,
  },
  {
    label: "Montør",
    value: ApiAccountTypeEnum.Installer,
  },
  {
    label: "Demokonto",
    value: ApiAccountTypeEnum.Demo,
  },
  {
    label: "Forhandler",
    value: ApiAccountTypeEnum.Retailer,
  },
  {
    label: "Medarbejderkonto",
    value: ApiAccountTypeEnum.Internal,
  },
  {
    label: "Opsagt",
    value: ApiAccountTypeEnum.Churned,
  },
]

export const accountFeatureOptions = [
  {
    label: "Rapporter",
    value: { REPORTS: AccountFeatureEnum.Reports },
  },
  {
    label: "Dashboard",
    value: { DASHBOARD: AccountFeatureEnum.Dashboard },
  },
]

export const accountFunctionsOptions = [
  {
    label: "Fører ID",
    value: { DRIVERS: AccountFunctionsEnum.Drivers },
  },
  {
    label: "Messenger",
    value: { MESSENGER: AccountFunctionsEnum.Messenger },
  },
]

export const accountFilterGroups = [
  {
    dataKey: "description",
    label: "Kontotype",
    options: accountType,
  },
]

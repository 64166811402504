import React, { useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import tw from "twin.macro"
import { navigate } from "gatsby"
import { UserContext } from "./context"
import { logout } from "services/auth"
import { AppContext } from "context"
import { GlobalAppActions, GlobalAppTypes } from "context/App/actions"
import { UserActions, UserActionTypes } from "./actions"

const StyledUserName = styled.span`
  line-height: 1.1;
  ${tw`text-center flex flex-col items-center`}
`

const StyledName = styled.span``

const StyledCompany = styled.span``

function UserName({ company, firstName, lastName, children, ...props }) {
  const { dispatch } = useContext(AppContext)
  const { dispatch: userDispatch } = useContext(UserContext)

  async function onLogOut() {
    const result = await logout()
    if (result) {
      dispatch(GlobalAppActions(GlobalAppTypes.Reset, null))
      userDispatch(UserActions(UserActionTypes.ResetUser, null))
      navigate("/")
    }
  }

  return (
    <StyledUserName {...props}>
      <StyledName title={`${firstName} ${lastName}`}>
        <span tw="block text-2xl font-bold">{`${firstName}`}</span>
      </StyledName>
      <StyledCompany tw="text-xl block mt-2" title={company}>
        {company}
      </StyledCompany>
      <a
        tw="text-brand-red-300 flex items-center cursor-pointer text-xl mt-4"
        onClick={onLogOut}
      >
        <span>Log ud</span>
      </a>
    </StyledUserName>
  )
}

export default UserName

UserName.defaultProps = {}
UserName.propTypes = {
  children: PropTypes.node,
}

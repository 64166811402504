import { useState, useRef, SetStateAction, Dispatch } from "react"

export const useFormData = <T extends object>(
  model?,
  onUpdateCallback?
): [
  T | null,
  (key: keyof T, value: any) => void,
  () => void,
  Dispatch<SetStateAction<T | null>>
] => {
  const modelRef = useRef(model)
  const [dataModel, setDataModel] = useState<T | null>(model)

  const updateModel = (key: keyof T, value: any) => {
    if (dataModel !== null) {
      setDataModel(
        (prev) =>
          ({
            ...prev,
            [key]: value,
          } as T)
      )
    }
    if (onUpdateCallback) onUpdateCallback()
  }

  const resetModel = () => {
    setDataModel(modelRef.current)
  }

  return [dataModel, updateModel, resetModel, setDataModel]
}

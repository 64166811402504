import { Search } from "app/Search"
import { SearchContext } from "app/Search/context"
import { freetextSearch } from "app/Search/helper"
import React, { useContext, useMemo } from "react"
import "twin.macro"

type DataListType = {
  items: any[]
  titleKey: string
  descriptionKey?: string | string[]
  searchKeys?: string[]
  includeSearch?: boolean
  onItemSelect?: (item: any) => void
  searchPlaceHolder?: string
}

export const DataList: React.FC<DataListType> = ({
  items,
  titleKey,
  descriptionKey,
  searchKeys,
  includeSearch,
  onItemSelect,
  searchPlaceHolder,
  ...props
}) => {
  const {
    state: { query },
  } = useContext(SearchContext)

  const datasetSearch = useMemo(() => {
    if (!searchKeys) return null
    return freetextSearch(items, {
      threshold: 0.15,
      location: 0,
      distance: 30,
      keys: searchKeys,
      includeScore: true,
    })
  }, [items, searchKeys])

  const dataset = useMemo(() => {
    if (includeSearch && datasetSearch && query.length >= 2) {
      return datasetSearch.search(query).map((x) => x.item)
    }
    return items
  }, [query, items, datasetSearch, includeSearch])

  return (
    <div {...props}>
      {includeSearch && datasetSearch && (
        <div tw="sticky top-0 bg-white p-4 px-8 z-50">
          <Search dataset={datasetSearch} placeholder={searchPlaceHolder} />
        </div>
      )}
      <ul tw="m-0 list-none p-0 border border-solid border-0 border-t border-brand-gray-brand">
        {dataset.map((x) => {
          const description =
            typeof descriptionKey === "object"
              ? descriptionKey
                  .map((k) => x[k])
                  .filter(Boolean)
                  .join(" - ")
              : descriptionKey
              ? x[descriptionKey]
              : null
          return (
            <li
              key={x.id}
              tw="px-8 py-6 border border-solid border-0 border-b border-brand-gray-brand"
              onClick={onItemSelect ? () => onItemSelect(x) : () => ({})}
            >
              <span tw="text-2xl">{x[titleKey]}</span>
              {description && (
                <span tw="block mt-2 text-xl opacity-60">{description}</span>
              )}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

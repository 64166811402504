import { ApiDevice, ApiVehicle, DeviceFeatureEnum } from "app/Devices/types"
import { privateApi } from "utils/api"

type CreateOrPatchVehicleResponse = {
  result: string
  vehicles?: ApiDevice[]
  error?: unknown
}

type DeleteVehicleResponse = {
  result: string
  error?: unknown
}

export type GetVehicleCalibrationResponse = {
  result: string
  vehicleCalibrationData?: any
  error?: unknown
}

export type GetOPatchVehicleFeatureResponse = {
  result: string
  features?: { [index: DeviceFeatureEnum]: any }
  error?: unknown
}

const createVehicle = async (
  args,
  accountID
): Promise<CreateOrPatchVehicleResponse> => {
  try {
    const {
      data: { result, count, vehicles },
    } = await privateApi().post(`/vehicles/${accountID}/cms`, args)

    return {
      result,
      vehicles,
    }
  } catch (e) {
    return {
      result: "error",
      error: e,
    }
  }
}

const patchVehicle = async (
  args: ApiVehicle,
  vehicle_id
): Promise<CreateOrPatchVehicleResponse> => {
  try {
    const {
      data: { result, vehicles },
    } = await privateApi().patch(`/vehicles/${vehicle_id}/cms`, args)

    return {
      result,
      vehicles,
    }
  } catch (e) {
    return {
      result: "error",
      error: e,
    }
  }
}

const deleteVehicle = async (vehicle_id): Promise<DeleteVehicleResponse> => {
  try {
    const {
      data: { result },
    } = await privateApi().delete(`/vehicles/${vehicle_id}/cms`)

    return {
      result,
    }
  } catch (e) {
    return {
      result: "error",
      error: e,
    }
  }
}

const getVehicleCalibration = async (
  vehicle_id
): Promise<GetVehicleCalibrationResponse> => {
  try {
    const {
      data: { result, count, vehicle },
    } = await privateApi().get(`/VehiclesCalibration/${vehicle_id}`)

    return {
      result: "OK",
      vehicleCalibrationData: vehicle,
    }
  } catch (e) {
    return {
      result: "error",
      error: e,
    }
  }
}

const patchVehicleCalibration = async (
  body,
  vehicle_id
): Promise<GetVehicleCalibrationResponse> => {
  try {
    const {
      data: { result, vehicle },
    } = await privateApi().patch(`/VehiclesCalibration/${vehicle_id}`, body)

    return {
      result: "OK",
      vehicleCalibrationData: vehicle,
    }
  } catch (e) {
    return {
      result: "error",
      error: e,
    }
  }
}

const getVehicleFeatures = async (
  vehicle_id
): Promise<GetOPatchVehicleFeatureResponse> => {
  try {
    const {
      data: { result, feature },
    } = await privateApi().get(`/VehiclesFeatures/${vehicle_id}/cms`)

    if (result === "OK") {
      return {
        result,
        features: feature[0],
      }
    } else {
      return {
        result: "error",
      }
    }
  } catch (e) {
    return {
      result: "error",
      error: e,
    }
  }
}

const patchVehicleFeatures = async (
  vehicle_id,
  body
): Promise<GetOPatchVehicleFeatureResponse> => {
  try {
    const {
      data: { result, feature },
    } = await privateApi().patch(`/VehiclesFeatures/${vehicle_id}`, body)

    if (result === "OK") {
      return {
        result,
        features: feature[0],
      }
    } else {
      return {
        result: "error",
      }
    }
  } catch (e) {
    return {
      result: "error",
      error: e,
    }
  }
}

export {
  createVehicle,
  getVehicleCalibration,
  patchVehicleCalibration,
  patchVehicle,
  deleteVehicle,
  getVehicleFeatures,
  patchVehicleFeatures,
}

import { useContext } from "react"
import { FirebaseContext } from "context/FirebaseProvider"
import {
  collection,
  doc,
  getDoc,
  getDocs,
  serverTimestamp,
  setDoc,
  updateDoc,
  writeBatch,
} from "firebase/firestore"
import {
  InstallationScreenEnum,
  InstallationStep,
} from "app/Installation/types"

export const useFirestoreDevices = () => {
  const { db } = useContext(FirebaseContext)

  const createFirebaseDevices = async (devices) => {
    if (db) {
      try {
        const batch = writeBatch(db)
        for (const device of devices) {
          batch.set(doc(db, `devices/${device.imei.toString()}`), device)
        }
        const result = await batch.commit()

        console.log(result)

        return "OK"
      } catch (error) {
        console.log(error)
        return "Error"
      }
    }
  }

  const saveFirebaseDevice = async (args: any, deviceID: string) => {
    if (db) {
      const firebaseDevice = doc(db, `devices`, deviceID)
      try {
        const res = await setDoc(
          firebaseDevice,
          {
            ...args,
            updated: serverTimestamp(),
          },
          { merge: true }
        )

        return "OK"
      } catch (error) {
        throw new Error(error as string)
      }
    }
  }

  const getFirebaseDevices = async () => {
    if (db) {
      try {
        const firebaseDevices = collection(db, `devices`)

        const res = await getDocs(firebaseDevices)

        return res.docs.map((doc) => doc.data())
      } catch (error) {
        console.log(error)
        return "Error"
      }
    }
  }

  const assignFirebaseDevicesToAccount = async (devices) => {
    if (db) {
      try {
        const batch = writeBatch(db)
        for (const device of devices) {
          batch.update(doc(db, `devices/${device.imei.toString()}`), device)
        }
        await batch.commit()

        return "OK"
      } catch (error) {
        console.log(error)
        return "Error"
      }
    }
  }

  const getInstallationStepsByImei = async (imei) => {
    if (db) {
      try {
        const firebaseDeviceInstallationSteps = collection(
          db,
          `devices/${imei}/installation`
        )
        const res = await getDocs(firebaseDeviceInstallationSteps)
        return res.docs.map((doc) => doc.data())
      } catch (error) {
        console.log(error)
        return "Error"
      }
    }
  }

  const saveInstallationStepByImei = async (
    imei,
    id: InstallationScreenEnum,
    model: InstallationStep
  ) => {
    if (db) {
      try {
        const firebaseDeviceInstallationStep = doc(
          db,
          `devices/${imei}/installation/${id}`
        )
        await setDoc(firebaseDeviceInstallationStep, {
          ...model,
        })
        return "OK"
      } catch (error) {
        console.log(error)
        return "Error"
      }
    }
  }

  return {
    createFirebaseDevices,
    saveFirebaseDevice,
    getFirebaseDevices,
    assignFirebaseDevicesToAccount,
    getInstallationStepsByImei,
    saveInstallationStepByImei,
  }
}

import { UserContext } from "app/User/context"
import { useCallback, useContext } from "react"
import { UserTypeEnum, UserTypes } from "app/User/types"

export const usePermissions = () => {
  const {
    state: { user },
  } = useContext(UserContext)

  const hasAccess = useCallback(
    (
      allowedUserRoles: UserTypeEnum[] = [],
      allowedUserFlags: (keyof UserTypes)[] = []
    ) => {
      if (user) {
        if (user?.is_cms_admin) return true
        if (allowedUserRoles.some((x) => user?.userTypes?.includes(x)))
          return true
        if (
          allowedUserFlags.find((key) => user[key] === 1 || user[key] === true)
        )
          return true
        return false
      }
      return false
    },
    [user]
  )

  return { hasAccess }
}

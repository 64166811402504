import { ActionMap, createActions } from "lib/reducer.types"

/* Action types */
export enum SearchTypes {
  SetResults = "Search/SetResults",
  SetQuery = "Search/SetQuery",
  SetSuggestions = "Search/SetSuggestions",
  ResetSearch = "Search/Reset",
}

type Actions = {
  [SearchTypes.SetResults]: { results: any[] }
  [SearchTypes.SetQuery]: { query: string }
  [SearchTypes.SetSuggestions]: { suggestions: any[] }
  [SearchTypes.ResetSearch]: undefined
}

export type SearchActionMap = ActionMap<Actions>[keyof ActionMap<Actions>]
export const SearchActions = createActions<Actions>()

import React, { useContext, useEffect, useMemo, useState } from "react"
import tw from "twin.macro"
import { useParams } from "@gatsbyjs/reach-router"
import { AccountsContext } from "app/Account/context"
import { AccountTypes, AccountsActions } from "app/Account/actions"
import { MainContainer } from "app/MainContainer"
import { DataTable } from "app/DataTable"
import { getAccountVehicles, getCustomerType } from "services/accounts"
import { Filter } from "app/DataTable/Filter"
import { deviceFilterGroups } from "app/Devices/helper"
import { CollectionFilter } from "utils/collection/filter"
import uniq from "lodash-es/uniq"
import { useAccount } from "app/Account/hooks"
import { SearchProvider } from "app/Search/context"
import { Button, Icon } from "@clevertrack/shared"
import { IconSizeEnum } from "lib/Icon"
import { deleteVehicle } from "services/vehicles"
import cogoToast from "@clevertrackdk/cogo-toast"

export const AccountDevices: React.FC = ({ ...props }) => {
  const {
    state: { accountVehicles, accounts, account },
    dispatch,
  } = useContext(AccountsContext)
  const [selectedFilters, setSelectedFilters] = useState<CollectionFilter[]>([])
  const [selectedDevices, setSelectedDevices] = useState<any[]>([])
  useAccount()

  useMemo(async () => {
    if (account) {
      await getCustomerType()
      const { accountVehicles } = await getAccountVehicles(account.id)
      console.log(accountVehicles)
      if (accountVehicles) {
        dispatch(
          AccountsActions(AccountTypes.SetAccountVehicles, { accountVehicles })
        )
      }
    }
    /* if (devices.length > 0 && account) {
      return devices.filter((device) => +device.accountID === account.id)
    } */
    return []
  }, [account])

  const onMultiSelectHandler = (rows) => {
    setSelectedDevices(rows)
  }

  const onDeleteHandler = async () => {
    const deletePromises = selectedDevices.map((x) => deleteVehicle(x.id))
    const results = await Promise.all(deletePromises)
    if (results.find((x) => x.result !== "OK")) {
      cogoToast.error("Nogle enheder kunne ikke slettes")
    } else {
      cogoToast.success("Enheder slettet")
    }
  }

  const availableDeviceFilterGroups = useMemo(() => {
    const availableDeviceTypes = uniq(accountVehicles.map((x) => x.deviceType))

    return deviceFilterGroups.map((group) => {
      if (group.dataKey === "deviceType") {
        return {
          ...group,
          options: group.options.filter((x) =>
            availableDeviceTypes.includes(x.label)
          ),
        }
      }
      return group
    })
  }, [accountVehicles, deviceFilterGroups])

  return (
    <MainContainer
      header={
        <>
          <h2>{account?.name}: Enheder</h2>
          {selectedDevices.length > 0 && (
            <Button
              type="button"
              variant="danger"
              icon="right"
              size="sm"
              tw="ml-8"
              onClick={() => onDeleteHandler()}
            >
              <span>
                {selectedDevices.length === 1
                  ? `Slet ${selectedDevices.length} enhed`
                  : `Slet ${selectedDevices.length} enheder`}
              </span>
              <Icon icon="trash-alt" size={IconSizeEnum.SM} />
            </Button>
          )}
        </>
      }
    >
      <SearchProvider>
        <Filter
          filterGroups={availableDeviceFilterGroups}
          filterByLabel
          onFilterUpdated={(filter) => setSelectedFilters(filter)}
        />
        <DataTable
          columns={[
            { key: "id", title: "ID" },
            { key: "name", title: "Navn" },
            { key: "note", title: "Beskrivelse" },
            { key: "description", title: "Montering" },
            { key: "deviceType", title: "Modul" },
            { key: "imei", title: "IMEI" },
            { key: "simicc", title: "SIMICC" },
          ]}
          columnConfig="2rem 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"
          onMultiSelect={onMultiSelectHandler}
          multiSelectIdentifierKey="unit_id"
          filters={selectedFilters}
          dataset={accountVehicles}
          searchKeys={["id", "imei", "simicc", "name", "deviceType"]}
          // onRowSelect={onSelectAccountHandler}
          tw="px-8"
        />
      </SearchProvider>
    </MainContainer>
  )
}

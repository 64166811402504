import { integrationApi } from "utils/api"

export const getCVRInfo = async (cvr) => {
  const result = await integrationApi().get(
    `https://cvrapi.dk/api?search=${cvr}&country=dk`
  )

  console.log(result)
  return result
}

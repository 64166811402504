import React, { useReducer, createContext } from "react"
import { DevicesActionMap, DevicesTypes } from "./actions"
import { Beacon, StockDevice, Tracker } from "./types"
import { updateCollectionItemByID } from "utils/array"

/**
 * Set initial state and create the context. The Provider can be wrapped around any component up the tree
 * This approach enables us to keep things really modular
 */

type InitialDevicesStateType = {
  devices: Partial<(Beacon | Tracker) & StockDevice>[] | []
  configurationFiles: any[]
}

const initialState: InitialDevicesStateType = {
  devices: [],
  configurationFiles: [],
}
const DevicesContext = createContext<{
  state: InitialDevicesStateType
  dispatch: React.Dispatch<any>
}>({ state: initialState, dispatch: () => null })

/**
 * Tracker reducer
 * Keeps track of selected trackers and the trackers loaded in from the backend
 */

const devicesReducer = (
  state: InitialDevicesStateType,
  action: DevicesActionMap
) => {
  switch (action.type) {
    case DevicesTypes.SetDevices:
      return {
        ...state,
        devices: action.payload.devices,
      }
    case DevicesTypes.UpdateDeviceByID:
      if (state.devices) {
        const newDevices = updateCollectionItemByID<
          Partial<(Beacon | Tracker) & StockDevice>[]
        >(state.devices, action.payload.device.id, action.payload.device)
        return {
          ...state,
          devices: newDevices,
        }
      }
      return state
    case DevicesTypes.SetConfigurationFiles:
      return {
        ...state,
        files: action.payload.files,
      }
    default:
      return state
  }
}

const DevicesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(devicesReducer, initialState)

  return (
    <DevicesContext.Provider value={{ state, dispatch }}>
      {children}
    </DevicesContext.Provider>
  )
}

export { DevicesContext, DevicesProvider }

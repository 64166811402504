import {
  DeviceCategoryEnum,
  Beacon,
  Tracker,
  ApiDevice,
  DeviceTypeEnum,
} from "app/Devices/types"
import { privateApi } from "utils/api"

/**
 * Fetch a list of devices
 */
type GetDevicesResponse = {
  result: string
  count: number
  devices: ApiDevice[]
}

export type DevicesResponse = {
  result: string
  count?: number
  devices?: (Beacon | Tracker)[]
  error?: unknown
}

const getDevices = async (): Promise<DevicesResponse> => {
  try {
    const {
      data: { result, count, devices },
    } = await privateApi().get<GetDevicesResponse>("/devices")

    return {
      count,
      result,
      devices: devices.map(
        ({
          IMEI,
          UUID,
          simicc,
          phone,
          disableLogging,
          account_id,
          ...rest
        }) => {
          const d: Beacon | Tracker = {
            ...rest,
            accountID: account_id,
            imei: IMEI,
            deviceCategory:
              simicc === ""
                ? DeviceCategoryEnum.Beacon
                : DeviceCategoryEnum.Tracker,
            simicc,
            uuid: UUID,
          }

          return d
        }
      ),
    }
  } catch (e) {
    return {
      result: "error",
      error: e,
    }
  }
}

const getDeviceByImei = async (imei) => {
  try {
    const {
      data: { result: CmsVehiclesResult, count, vehicles: CmsVehicles },
    } = await privateApi().get(`/CmsVehicles/${imei}`)

    if (
      CmsVehiclesResult === "OK" &&
      CmsVehicles.length === 1 &&
      CmsVehicles[0].id
    ) {
      const {
        data: { result, count, feature },
      } = await privateApi().get(`/VehiclesFeatures/${CmsVehicles[0].id}/cms`)

      const {
        data: { displayKey },
      } = await privateApi().get(`/displayKeys/${CmsVehicles[0].id}/cms`)

      const {
        data: { vehicle },
      } = await privateApi().get(`/VehiclesCalibration/${CmsVehicles[0].id}`)

      const {
        data: { commands },
      } = await privateApi().get(`/CmsDeviceCommand/${imei}`)

      if (result === "OK" && feature.length === 1) {
        return {
          result,
          vehicle: {
            ...CmsVehicles[0],
            features: feature[0],
            displayKeys: displayKey,
            calibration: vehicle,
            commands: commands,
          },
        }
      }
    }

    return {
      result: "error",
    }
  } catch (e) {
    return {
      result: "error",
      error: e,
    }
  }
}

/**
 * Create a device
 */
type CreateDevicesResponse = {
  result: string
  unit_id: number
}

export type CreatedDevicesResponse = {
  result: string
  data?: {
    imei: string
    unit_id: number
  }
  error?: unknown
}

type CreateDevicePayload = {
  deviceType: DeviceTypeEnum
  imei: string
  comment?: string
  simicc: string
  uuid: string
}

const createDevice = async (
  payload: CreateDevicePayload
): Promise<CreatedDevicesResponse> => {
  try {
    const {
      data: { result, unit_id },
    } = await privateApi().post<CreateDevicesResponse>("/devices", payload)

    return {
      data: {
        imei: payload.imei,
        unit_id,
      },
      result,
    }
  } catch (e) {
    return {
      result: "error",
      error: e,
    }
  }
}

export { getDevices, getDeviceByImei, createDevice }

import React, { useContext, useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { Icon } from "@clevertrack/shared"
import { Select } from "lib/Select"
import { CollectionFilter } from "utils/collection/filter"
import { FilterGroups } from "./types"
import { SearchContext } from "app/Search/context"
import { SearchActions, SearchTypes } from "app/Search/actions"

const StyledFilterComponent = styled.div`
  ${tw`p-4 px-8 flex items-center relative z-50`}

  .selectedFilters {
    ${tw`ml-8 flex items-center space-x-4`}
  }

  .selectedFilter {
    ${tw`bg-brand-secondary text-white flex items-center relative`}

    > span {
      ${tw`p-2 pr-12`}
      &.deselect {
        width: 2rem;
        ${tw`absolute pr-0 right-0 top-0 bottom-0 transition-all cursor-pointer ml-4 flex items-center hover:bg-brand-red-400`}
        svg {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }
`

type FilterProps = {
  onFilterUpdated: (filter: CollectionFilter[]) => void
  filterGroups: FilterGroups
  filterByLabel?: boolean
}

export const Filter: React.FC<FilterProps> = ({
  filterGroups,
  filterByLabel = false,
  onFilterUpdated,
  children,
  ...props
}) => {
  const {
    state: { query },
    dispatch,
  } = useContext(SearchContext)
  const [selectedFilters, setSelectedFilters] = useState<CollectionFilter[]>([])
  const [selectValue, setSelectValue] = useState(null)

  const filterGroupsMemoized = useMemo(() => {
    if (selectedFilters.length > 0) {
      return filterGroups.map((group) => {
        const selectedFiltersOfGroup = selectedFilters
          .filter((filter) => filter.group === group.label)
          .map((x) => x.value)
        return {
          ...group,
          options: group.options.filter(
            (x) =>
              !selectedFiltersOfGroup.includes(
                filterByLabel ? x.label : x.value
              )
          ),
        }
      })
    }

    return filterGroups
  }, [filterGroups, selectedFilters])

  const onSelectHandler = (val) => {
    const group = [...filterGroups].find((x) => x.options.includes(val))

    if (group) {
      const selectedFilter: CollectionFilter = {
        key: group.dataKey,
        group: group.label,
        label: `${group.label}: ${val.label}`,
        value: filterByLabel ? val.label : val.value,
      }
      setSelectedFilters((prev) => [...prev, selectedFilter])
      onResetFreetext()
    }

    setSelectValue(null)
  }

  const onDeselectHandler = (val) => {
    setSelectedFilters((prev) => prev.filter((x) => x.value !== val))
  }

  useEffect(() => {
    if (onFilterUpdated) onFilterUpdated(selectedFilters)
  }, [selectedFilters, onFilterUpdated])

  const onInputChange = (q) => {
    if (q.length > 0) {
      dispatch(SearchActions(SearchTypes.SetQuery, { query: q }))
    }
  }

  const onResetFreetext = () => {
    dispatch(SearchActions(SearchTypes.ResetSearch))
  }

  return (
    <StyledFilterComponent {...props}>
      <div tw="w-1/5">
        <Select
          placeholder="Filtrér"
          tw="bg-brand-gray-brand"
          onChange={onSelectHandler}
          options={filterGroupsMemoized}
          value={selectValue}
          onInputChange={onInputChange}
          noOptionsMessage={() => "Ingen filtre, søger i data"}
        />
      </div>
      <div className="selectedFilters">
        {selectedFilters.map((x) => (
          <span key={x.value} className="selectedFilter">
            <span>{x.label}</span>
            <span
              onClick={() => onDeselectHandler(x.value)}
              className="deselect"
            >
              <Icon icon="close" />
            </span>
          </span>
        ))}
        {query && query.length >= 2 && (
          <span className="selectedFilter">
            <span>{`Fritekst: ${query}`}</span>
            <span onClick={() => onResetFreetext()} className="deselect">
              <Icon icon="close" />
            </span>
          </span>
        )}
      </div>
    </StyledFilterComponent>
  )
}

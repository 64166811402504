import { useContext } from "react"
import { FirebaseContext } from "context/FirebaseProvider"
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  serverTimestamp,
  deleteDoc,
  getDocs,
  collection,
} from "firebase/firestore"

export const useFirestoreUser = () => {
  const { db } = useContext(FirebaseContext)

  const createFirebaseUser = async (id: string, args?: any) => {
    if (db) {
      try {
        await setDoc(doc(db, "users", id), { id, ...args })
      } catch (error) {
        console.log(error)
      }
    }
  }

  const deleteFirebaseUser = async (userID: string) => {
    if (db) {
      try {
        const result = await deleteDoc(doc(db, "users", userID.toString()))
        console.log(result)
        return "OK"
      } catch (error) {
        console.log(error)
        return "error"
      }
    }
  }

  const getFirebaseUser = async (userID: string) => {
    if (db) {
      try {
        const result = await getDoc(doc(db, "users", userID.toString()))
        return result.data()
      } catch (error) {
        return "error"
      }
    }
  }

  const saveFirebaseUser = async (args, userID) => {
    if (db) {
      try {
        const firebaseUser = doc(db, "users", userID.toString())
        let firebaseUserDoc = await getDoc(firebaseUser)

        if (!firebaseUserDoc.exists()) {
          await createFirebaseUser(userID.toString(), args)
        } else {
          await updateDoc(firebaseUser, {
            ...args,
            updated: serverTimestamp(),
          })
        }

        const updatedDoc = await getDoc(firebaseUser)

        return updatedDoc.data()
      } catch (error) {
        throw new Error(error as string)
      }
    }
  }

  const getAllFirebaseUsers = async () => {
    if (db) {
      try {
        const result = await getDocs(collection(db, "users"))
        return result.docs.map((x) => x.data())
      } catch (error) {
        return null
      }
    }
  }

  return {
    saveFirebaseUser,
    deleteFirebaseUser,
    getFirebaseUser,
    getAllFirebaseUsers,
  }
}

import { useContext } from "react"
import {
  ListResult,
  StorageReference,
  deleteObject,
  getDownloadURL,
  listAll,
  ref,
  uploadBytesResumable,
} from "firebase/storage"
import { FirebaseContext } from "context/FirebaseProvider"

export const useFirebaseStorage = () => {
  const { storage } = useContext(FirebaseContext)

  const uploadFile = (path: string, file) => {
    try {
      if (storage) {
        const storageRef = ref(storage, path)
        const result = uploadBytesResumable(storageRef, file)
        return result
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getDirectoryList = async (path: string) => {
    try {
      if (storage) {
        const listRef = ref(storage, path)
        const result = await listAll(listRef)
        const resultsWithLinks = await Promise.all([
          ...result.items.map(async (item: StorageReference) => {
            const downloadURL = await getDownloadURL(item)
            return {
              ref: item,
              name: item.name,
              downloadURL,
            }
          }),
        ])
        return resultsWithLinks
      }
    } catch (error) {
      console.log(error)
      return []
    }
  }

  const getDownloadLink = async (path: string) => {
    if (storage) {
      const gsReference = ref(storage, path)
      const downloadURL = await getDownloadURL(gsReference)
      return gsReference
    }
  }

  const deleteFile = async (storageObject: StorageReference) => {
    if (storage) {
      const deleteResult = await deleteObject(storageObject)
      return deleteResult
    }
  }

  const deleteFileByPath = async (path: string) => {
    try {
      if (path && storage) {
        const gsReference = ref(storage, path)
        const deleteResult = await deleteObject(gsReference)
        return deleteResult
      }
    } catch (error) {
      return error
    }
  }

  return {
    uploadFile,
    getDirectoryList,
    getDownloadLink,
    deleteFile,
    deleteFileByPath,
  }
}

import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import "react-vis/dist/style.css"
import { RadialChart } from "react-vis"

const StyledDonutMetric = styled.article`
  width: 16rem;
  height: 16rem;
  ${tw`flex flex-col items-center justify-center relative bg-white shadow-lg rounded-full`}

  .kpiValue {
    font-family: ${(props) => props.theme.fontStacks.bold};
    font-size: 3.6rem;
    ${tw`m-0 mt-4 mb-2 block`}
  }

  .kpiPercent {
    font-family: ${(props) => props.theme.fontStacks.subheading};
    ${tw`m-0 mb-4 text-xl block`}
  }

  .chart {
    position: absolute;
    top: 0;
    left: 0;
  }
`

export const DonutMetric: React.FC = ({
  id,
  title,
  source,
  segment,
  chartStyle,
  ...props
}) => {
  const series = [
    {
      id,
      angle: (100 - (segment.length / source.length) * 100).toFixed(2),
      style: {
        stroke: "#fff",
        fill: "#fff",
      },
    },
    {
      id,
      angle: ((segment.length / source.length) * 100).toFixed(2),
      style: chartStyle,
    },
  ]

  return (
    <StyledDonutMetric>
      <h2>{title}</h2>
      <span className="kpiValue">{segment.length}</span>
      <span className="kpiPercent">
        {((segment.length / source.length) * 100).toFixed(2)}%
      </span>
      <div className="chart">
        <RadialChart
          data={series}
          width={160}
          height={160}
          padAngle={0.15}
          radius={75}
          innerRadius={70}
          animation={true}
        />
      </div>
    </StyledDonutMetric>
  )
}

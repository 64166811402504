import React, { useContext, useEffect, useMemo, useRef } from "react"
import "twin.macro"
import {
  FlowHeader,
  ScreenHeader,
  StyledPopoverContentContainer,
} from "./components"
import {
  DeviceInformationModel,
  InstallationScreenEnum,
  InstallationStepStateEnum,
  InstallationStepType,
} from "../types"
import { useFormData } from "hooks/useFormData"
import {
  Button,
  ButtonGroup,
  Form,
  FormField,
  Icon,
  Textarea,
  ValidationInput,
} from "@clevertrack/shared"
import { FormFieldToggle } from "components/FormFieldToggle"
import { InstallationContext } from "../context"
import { useFileUpload } from "app/FileUpload/hooks"
import { PhotoUpload } from "./components/PhotoUpload"
import { patchVehicle } from "services/vehicles"
import { DeviceTypeCategoryEnum } from "app/Devices/types"
import { useInstallation } from "../hooks"
import { getInstallationStepState } from "./helper"
import debounce from "lodash-es/debounce"
import cogoToast from "@clevertrackdk/cogo-toast"
import isEqual from "lodash-es/isEqual"

export const DeviceInformation: React.FC<InstallationStepType> = ({
  onSave,
  ...props
}) => {
  const {
    state: { account, device, installationSteps, currentScreen },
  } = useContext(InstallationContext)

  const [currentModel, updateModel, resetModel, setCurrentModel] = useFormData<
    DeviceInformationModel
  >(
    installationSteps.find(
      (step) => step.stepID === InstallationScreenEnum.DeviceInformation
    )?.data
  )
  const { deviceTypeCategory, setCurrentScreen } = useInstallation()
  const { onUploadDocumentHandler, uploadMap } = useFileUpload(
    `${account?.id}/${device?.imei}`,
    account?.id
  )

  const stepData = useMemo(() => {
    if (installationSteps) {
      const step = installationSteps.find(
        (step) => step.stepID === InstallationScreenEnum.DeviceInformation
      )
      if (step) {
        return step
      }
      return null
    }
    return null
  }, [installationSteps])

  const autoSaveFunc = useRef(
    debounce((newData) => {
      onSave(InstallationScreenEnum.DeviceInformation, newData, null, {
        autoSave: true,
      })
    }, 2000)
  )

  useMemo(() => {
    if (
      currentModel &&
      currentScreen === InstallationScreenEnum.DeviceInformation &&
      !isEqual(stepData?.data, currentModel)
    ) {
      const newData = {
        ...stepData,
        data: currentModel,
        stepState: InstallationStepStateEnum.Incomplete,
      }
      autoSaveFunc.current.cancel()
      autoSaveFunc.current(newData)
    }
  }, [currentModel, stepData, autoSaveFunc, currentScreen])

  const onSubmitHandler = () => {
    const newData = {
      ...stepData,
      data: currentModel,
      stepState: getInstallationStepState(
        currentModel,
        stepData.optionalDataProps
      ),
    }
    const apiSave = patchVehicle(
      {
        name: currentModel.vehicleName,
        note: currentModel.vehicleDescription ?? "",
        description: device?.description,
        customer_id: device?.customer_id,
        subscription_type: device?.subscription_type,
        subscription_addon: device?.subscription_addon,
        aempid: device?.aempid,
      },
      device.id
    )

    onSave(
      InstallationScreenEnum.DeviceInformation,
      newData,
      Promise.all([apiSave])
    )
  }

  const onAcceptFilesHandler = async (files, filename, key) => {
    await onUploadDocumentHandler(files, filename, key)
  }

  useMemo(() => {
    Object.values(uploadMap).map((value) => {
      if (value.key) {
        updateModel(value.key, value.downloadURL)
        updateModel(`${value.key}Path`, value.path)
      }
    })
  }, [uploadMap])

  useMemo(() => {
    const str = [currentModel?.vehicleBrand, currentModel?.vehicleModel]
    if (currentModel?.vehicleNumber) {
      if (currentModel?.vehicleBrand || currentModel?.vehicleModel) {
        str.unshift(`-`)
      }
      str.unshift(`${currentModel.vehicleNumber}`)
    }
    updateModel("vehicleName", str.filter(Boolean).join(" "))
  }, [
    currentModel?.vehicleBrand,
    currentModel?.vehicleModel,
    currentModel?.vehicleNumber,
  ])

  useMemo(() => {
    const str = [
      currentModel?.vehicleFrameNumber
        ? `Stelnr: ${currentModel.vehicleFrameNumber}`
        : null,
      currentModel?.vehicleYear ? `Årg: ${currentModel?.vehicleYear}` : null,
      ,
    ]
    updateModel("vehicleDescription", str.filter(Boolean).join(" "))
  }, [currentModel?.vehicleFrameNumber, currentModel?.vehicleYear])

  return (
    <StyledPopoverContentContainer>
      <FlowHeader name={currentModel?.legacyVehicleName} />
      <ScreenHeader>Enhedsinformation</ScreenHeader>
      <Form onSubmit={onSubmitHandler} {...props}>
        <div tw="p-4 space-y-8 pb-16">
          <FormFieldToggle
            toggleQuestion={`Har enheden ikke et ${
              [
                DeviceTypeCategoryEnum.Beacon,
                DeviceTypeCategoryEnum.GPSTrackerWithBattery,
              ].includes(deviceTypeCategory)
                ? `materielnummer`
                : `maskin-/køretøjsnummer`
            }?`}
            untoggleText="Fjern feltet"
            toggleText={`Tilføj ${
              [
                DeviceTypeCategoryEnum.Beacon,
                DeviceTypeCategoryEnum.GPSTrackerWithBattery,
              ].includes(deviceTypeCategory)
                ? `materielnummer`
                : `maskin-/køretøjsnummer`
            } +`}
            onDisable={() => {
              const { vehicleNumber, ...newModel } = currentModel
              setCurrentModel(newModel)
            }}
            onEnable={() => {
              updateModel(
                "vehicleNumber",
                stepData?.data?.vehicleNumber ?? null
              )
            }}
            defaultToggled
          >
            <FormField
              label={
                [
                  DeviceTypeCategoryEnum.Beacon,
                  DeviceTypeCategoryEnum.GPSTrackerWithBattery,
                ].includes(deviceTypeCategory)
                  ? `Materielnummer`
                  : `Maskin-/køretøjsnummer`
              }
            >
              <ValidationInput
                value={currentModel.vehicleNumber}
                placeholder={
                  [
                    DeviceTypeCategoryEnum.Beacon,
                    DeviceTypeCategoryEnum.GPSTrackerWithBattery,
                  ].includes(deviceTypeCategory)
                    ? `Indtast materielnummer`
                    : `Indtast maskin- eller køretøjsnummer`
                }
                onChange={(e) => updateModel("vehicleNumber", e.target.value)}
              />
            </FormField>
          </FormFieldToggle>
          {deviceTypeCategory !== DeviceTypeCategoryEnum.Beacon && (
            <FormFieldToggle
              toggleQuestion="Er enheden ikke indregistreret?"
              untoggleText="Fjern feltet"
              toggleText="Tilføj registreringsnummer +"
              onDisable={() => {
                const {
                  vehicleRegistrationNumber,
                  vehicleLicensePlatePhoto,
                  vehicleLicensePlatePhotoPath,
                  ...newModel
                } = currentModel
                setCurrentModel(newModel)
              }}
              onEnable={() => {
                updateModel(
                  "vehicleRegistrationNumber",
                  stepData?.data?.vehicleRegistrationNumber ?? null
                )
                updateModel(
                  "vehicleLicensePlatePhoto",
                  stepData?.data?.vehicleLicensePlatePhoto ?? null
                )
                updateModel(
                  "vehicleLicensePlatePhotoPath",
                  stepData?.data?.vehicleLicensePlatePhotoPath ?? null
                )
              }}
              defaultToggled
            >
              <FormField label="Registreringsnummer">
                <ValidationInput
                  value={currentModel.vehicleRegistrationNumber}
                  placeholder="Indtast registreringsnummer"
                  onChange={(e) =>
                    updateModel(
                      "vehicleRegistrationNumber",
                      e.target.value.toUpperCase()
                    )
                  }
                />
              </FormField>
              <h4>Billede af reg. nr.</h4>
              <PhotoUpload
                photoPropertyKey="vehicleLicensePlatePhoto"
                photoPropertyValue={currentModel?.vehicleLicensePlatePhoto}
                photoFilename="license-plate.jpeg"
                photoPath={currentModel?.vehicleLicensePlatePhotoPath}
                uploadLabel=""
                uploadDescription="Tydeligt billede af nummerplade og synligt køretøj"
                onUpdateModel={updateModel}
                onAcceptFilesHandler={onAcceptFilesHandler}
              />
            </FormFieldToggle>
          )}
          <FormField label="Mærke">
            <ValidationInput
              value={currentModel.vehicleBrand}
              placeholder="Indtast mærke"
              onChange={(e) => updateModel("vehicleBrand", e.target.value)}
            />
          </FormField>
          <FormField label="Model">
            <ValidationInput
              value={currentModel.vehicleModel}
              placeholder="Indtast model"
              onChange={(e) => updateModel("vehicleModel", e.target.value)}
            />
          </FormField>
          <FormField label="Årgang">
            <ValidationInput
              value={currentModel.vehicleYear}
              placeholder="Indtast årgang"
              onChange={(e) => updateModel("vehicleYear", e.target.value)}
            />
          </FormField>
          {/* knacr811fps017566 */}
          <FormField label="Stelnummer">
            <ValidationInput
              value={currentModel.vehicleFrameNumber}
              placeholder="Indtast stelnummer"
              onChange={(e) =>
                updateModel("vehicleFrameNumber", e.target.value.toUpperCase())
              }
            />
          </FormField>
          <h4>Billede af typeplade</h4>
          <PhotoUpload
            photoPropertyKey="vehicleTypePlatePhoto"
            photoPropertyValue={currentModel?.vehicleTypePlatePhoto}
            photoFilename="typeplate-with-vin.jpeg"
            photoPath={currentModel?.vehicleTypePlatePhotoPath}
            uploadLabel=""
            uploadDescription="Aftør for evt. snavs så al teksten er tydelig"
            onUpdateModel={updateModel}
            onAcceptFilesHandler={onAcceptFilesHandler}
          />
          <FormField label="Særlige kendetegn (valgfri)">
            <Textarea
              defaultValue={currentModel.vehicleUniqueness}
              placeholder="Indtast særlige kendetegn eller ekstra udstyr"
              onChange={(e) => updateModel("vehicleUniqueness", e.target.value)}
            />
          </FormField>
          <FormField label="Navn (genereret)">
            <ValidationInput
              value={currentModel.vehicleName ?? device?.name}
              placeholder="Enhedens navn"
              onChange={(e) => updateModel("vehicleName", e.target.value)}
            />
          </FormField>
          <FormField label="Beskrivelse (genereret)">
            <ValidationInput
              value={currentModel.vehicleDescription}
              placeholder="F.eks. årgang og stelnummer"
              onChange={(e) =>
                updateModel("vehicleDescription", e.target.value)
              }
            />
          </FormField>
        </div>
        <ButtonGroup sticky="bottom" tw="bg-white px-4">
          <Button type="button" variant="cancel">
            <span
              tw="flex items-center"
              onClick={() => setCurrentScreen(InstallationScreenEnum.Tasks)}
            >
              <Icon icon="chevron-left" tw="w-4 h-4 mr-2" />
              <span tw="text-xl font-normal">Tilbage</span>
            </span>
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={
              stepData &&
              getInstallationStepState(
                currentModel,
                stepData?.optionalDataProps
              ) !== InstallationStepStateEnum.Completed
            }
          >
            Gem
          </Button>
        </ButtonGroup>
      </Form>
    </StyledPopoverContentContainer>
  )
}

import { GlobalSearch } from "app/Search/Global"
import { SearchProvider } from "app/Search/context"
import React from "react"
import styled from "styled-components"
import tw from "twin.macro"

const StyledMainContainer = styled.div`
  header {
    ${tw`bg-white py-4 px-8 flex items-center border-0 border-solid border-b border-b-brand-gray-light sticky top-0 z-100`}

    h1, h2, h3 {
      ${tw`m-0`}
    }
  }
`

type MainContainerTypes = {
  header: React.ReactElement
  footer?: React.ReactElement
}

export const MainContainer: React.FC<MainContainerTypes> = ({
  header,
  footer,
  children,
  ...props
}) => {
  return (
    <StyledMainContainer {...props}>
      <header>
        {header}
        <SearchProvider>
          <GlobalSearch tw="w-1/3 ml-auto" />
        </SearchProvider>
      </header>
      {children}
      {footer ? <footer>{footer}</footer> : null}
    </StyledMainContainer>
  )
}

import React, { useContext, useMemo, useState } from "react"
import "twin.macro"
import { UserContext } from "app/User/context"
import { MainContainer } from "app/MainContainer"
import { DataTable } from "app/DataTable"
import { UserType } from "app/DataTable/components/UserType"
import { format } from "date-fns"
import { AccountsContext } from "app/Account/context"
import { userFilterGroups } from "app/User/helper"
import { CollectionFilter } from "utils/collection/filter"
import { Filter } from "app/DataTable/Filter"
import { FilterGroup } from "app/DataTable/types"
import uniq from "lodash-es/uniq"
import { ApiAccountTypeEnum } from "app/Account/types"
import { SearchProvider } from "app/Search/context"
import { navigate } from "gatsby"
import { Button, Icon } from "@clevertrack/shared"
import { IconSizeEnum } from "lib/Icon"

export const Users: React.FC = ({ ...props }) => {
  const {
    state: { users },
  } = useContext(UserContext)
  const {
    state: { accounts },
  } = useContext(AccountsContext)
  const [selectedFilters, setSelectedFilters] = useState<CollectionFilter[]>([])

  const [mappedUsers, accountsFilterGroup] = useMemo(() => {
    const userMap = users.map((user) => ({
      ...user,
      name: [user.firstName, user.lastName].join(" "),
      account: accounts.find((acc) => +acc.id === +user.account_id)?.name,
    }))

    const accountsFilterGroup: FilterGroup = {
      dataKey: "account",
      label: "Konto",
      options: uniq(
        accounts
          .filter((acc) => acc.description !== ApiAccountTypeEnum.Churned)
          .map((acc) => acc.name)
      ).map((x) => ({
        label: x,
        value: x,
      })),
    }

    return [userMap, accountsFilterGroup]
  }, [users, accounts])

  const filters = [...userFilterGroups, accountsFilterGroup]

  return (
    <MainContainer
      header={
        <>
          <h2>Brugere</h2>
          <Button
            type="button"
            variant="primary"
            icon="right"
            size="sm"
            tw="ml-8"
            onClick={() => navigate("/app/users/create")}
          >
            <span>Opret ny bruger</span>
            <Icon icon="plus" size={IconSizeEnum.SM} />
          </Button>
        </>
      }
    >
      <SearchProvider>
        <Filter
          filterGroups={filters}
          onFilterUpdated={(filter) => setSelectedFilters(filter)}
        />
        <DataTable
          columns={[
            { key: "id", title: "ID" },
            { key: "name", title: "Navn" },
            { key: "username", title: "Brugernavn" },
            { key: "phoneNumber", title: "Telefon" },
            { key: "account", title: "Konto" },
            {
              key: "userType",
              title: "Type",
              component: UserType,
            },
            {
              key: "last_login",
              title: "Seneste login",
              formatData: (date) => format(new Date(date), "dd-MM-yyyy HH:mm"),
            },
          ]}
          filters={selectedFilters}
          dataset={mappedUsers}
          columnConfig="4rem 1fr 1fr 0.75fr 1fr 0.5fr 1fr"
          searchKeys={["username", "name", "account", "email", "phoneNumber"]}
          onRowSelect={({ id }) => navigate(`/app/users/${id}`)}
          tw="px-8"
        />
      </SearchProvider>
    </MainContainer>
  )
}

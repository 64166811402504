import React, { useCallback, useContext, useMemo, useState } from "react"
import tw from "twin.macro"
import { isIMEI } from "validator"
import QrReader from "react-qr-scanner"
import { MainContainer } from "app/MainContainer"
import { useFirebaseFunctions } from "services/firebase-functions/functions"
import {
  Button,
  ButtonGroup,
  Form,
  FormField,
  Input,
  Textarea,
} from "@clevertrack/shared"
import { DataTable } from "app/DataTable"
import format from "date-fns/format"
import { DevicesContext } from "app/Devices/context"
import uniqBy from "lodash-es/uniqBy"
import { Filter } from "app/DataTable/Filter"
import { CollectionFilter } from "utils/collection/filter"
import { useFirestoreDevices } from "services/firestore/devices"
import cogoToast from "@clevertrackdk/cogo-toast"
import { DeviceStockStatusEnum } from "app/Devices/types"
import { Select } from "lib/Select"
import { deviceTypeObject } from "app/Devices/helper"
import { createDevice } from "services/devices"

export const CreateDevice: React.FC = ({ ...props }) => {
  const {
    state: { devices },
  } = useContext(DevicesContext)
  const { getFotaDevice, getInactiveFotaDevices } = useFirebaseFunctions()
  const [imeiNumbers, setImeiNumbers] = useState<string>("")
  const [dataset, setDataset] = useState<any[] | null>(null)
  const [selectedFilters, setSelectedFilters] = useState<CollectionFilter[]>([])
  const [batchname, setBatchname] = useState<string>("")
  const [newDevices, setNewDevices] = useState<any[]>([])
  const [qrScannerLoaded, setQrScannerLoaded] = useState(false)
  const { createFirebaseDevices } = useFirestoreDevices()

  const onAddImeiNumbers = (e) => {
    setImeiNumbers(e.target.value)
  }

  const onLoadInactiveDevices = async () => {
    const res = await getInactiveFotaDevices(
      batchname !== "" ? { shipmentID: batchname } : { shipmentID: null }
    )
    if (res.data.status === "OK") {
      resolveDataset(res.data.devices)
    }
  }

  const deviceFilterGroups = useMemo(() => {
    const shipments = uniqBy(dataset, "shipment")
      .filter((x) => x.shipment !== "")
      .map((x) => x.shipment)
      .filter(Boolean)
    const models = uniqBy(dataset, "model")
      .filter((x) => x.model !== "")
      .map((x) => x.model)
      .filter(Boolean)
    return [
      {
        dataKey: "shipment",
        label: "Forsendelse",
        options: shipments.map((x) => ({ label: x, value: x })),
      },
      {
        dataKey: "model",
        label: "Model",
        options: models.map((x) => ({ label: x, value: x })),
      },
    ]
  }, [dataset])

  useMemo(async () => {
    const validImeiNumbers = imeiNumbers.split("\n").filter((x) => isIMEI(x))
    if (validImeiNumbers.length > 0) {
      const resolvedFotaDevices = validImeiNumbers.map((x) =>
        getFotaDevice({ deviceImei: +x })
      )
      const results = await Promise.all(resolvedFotaDevices)
      const rawDevices = results
        .filter((req) => req.data.status === "OK")
        .map((req) => req.data.device)
      resolveDataset(rawDevices)
    } else {
      return setDataset(null)
    }
  }, [imeiNumbers])

  const resolveDataset = (rawDevices) => {
    const newDevices = rawDevices.map((device) => {
      const foundDevice = devices.find((x) => +x.imei === device.imei)
      return {
        imei: device.imei,
        model: device.model ?? device.description,
        productCode: device.product_code,
        account: foundDevice?.account,
        shipment: device.shipment,
        simicc:
          device.iccid && device.iccid.length === 20
            ? device.iccid.substr(10, 9)
            : device.iccid,
        activityStatus: device.activity_status,
        createdAt: device.created_at,
        deviceType: foundDevice?.deviceType,
        deviceTypeName: foundDevice?.deviceTypeName,
      }
    })
    setDataset(newDevices)
  }

  const onMultiSelectHandler = (rows) => {
    if (uniqBy(rows, "shipment").length === 1) {
      setBatchname(rows[0].shipment)
    }
    setNewDevices(rows)
  }

  const onScanQRHandler = useCallback(
    (result) => {
      if (result && !imeiNumbers.includes(result.text)) {
        setImeiNumbers((prev) =>
          prev.length > 0 ? prev.concat(`\n${result.text}`) : result.text
        )
      }
    },
    [imeiNumbers]
  )

  const onChangeModuleTypeHandler = (opt) => {
    if (dataset) {
      const mappedDevices = dataset.map((dev) => ({
        ...dev,
        deviceType: opt.value,
        deviceTypeName: opt.label,
      }))

      setDataset(mappedDevices)
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    try {
      const createUnitsPromises = newDevices.map((item) => {
        return createDevice({
          imei: item.imei,
          uuid: item.uuid,
          simicc: item.simicc,
          comment: "",
          deviceType: item.deviceType,
        })
      })

      const apiCreateResults = await Promise.all(createUnitsPromises)

      const payload = newDevices.map((item) => {
        const apiResult = apiCreateResults.find(
          (result) => result.data?.imei === item.imei
        )
        return {
          ...item,
          shipment: batchname ?? null,
          account: null,
          stockStatus: DeviceStockStatusEnum.Stock,
          stockCreatedDate: new Date(),
          unit_id: apiResult?.data?.unit_id,
        }
      })

      const res = await createFirebaseDevices(payload)

      if (res === "OK") {
        cogoToast.success("Nye enheder er lagerført")
      } else {
        cogoToast.error("Kunne ikke oprette batch")
      }
    } catch (error) {
      cogoToast.error("Kunne ikke oprette batch")
    }
  }

  return (
    <MainContainer
      header={
        <>
          <h2>Opret nye lagerenheder</h2>
        </>
      }
    >
      <div tw="p-8">
        <Form onSubmit={onSubmit}>
          <div tw="flex items-center">
            <FormField label="Angiv batchnavn" tw="w-1/4">
              <Input
                type="text"
                id="batchname"
                placeholder="Pakkelistens navn"
                value={batchname}
                onChange={(e) => setBatchname(e.target.value)}
              />
            </FormField>
            <ButtonGroup tw="p-0 space-x-2 ml-8">
              <Button
                type="button"
                variant="default"
                onClick={onLoadInactiveDevices}
              >
                Hent batch
              </Button>
              <Button type="submit" variant="primary">
                Gem batch
              </Button>
            </ButtonGroup>
          </div>
          <div tw="flex items-center space-x-8 mt-8">
            <FormField label="IMEI-numre" tw="w-1/4">
              <Textarea
                placeholder="Indsæt IMEI-numre (adskil med ny linje)"
                onBlur={onAddImeiNumbers}
                onChange={onAddImeiNumbers}
                value={imeiNumbers}
              ></Textarea>
            </FormField>
            <div>
              {qrScannerLoaded ? (
                <QrReader css={tw`w-36 h-36`} onScan={onScanQRHandler} />
              ) : (
                <Button onClick={() => setQrScannerLoaded(true)}>
                  Åbn kamera
                </Button>
              )}
            </div>
          </div>
          {dataset && dataset.length > 0 && (
            <div tw="mt-8">
              <FormField label="Modultype" tw="w-1/4">
                <Select
                  tw="pt-6"
                  options={deviceTypeObject}
                  placeholder="Vælg modultype"
                  onChange={onChangeModuleTypeHandler}
                />
              </FormField>
            </div>
          )}
        </Form>
      </div>
      {dataset && (
        <>
          <Filter
            filterGroups={deviceFilterGroups}
            onFilterUpdated={(filter) => setSelectedFilters(filter)}
          />
          <div tw="px-8 p-4 border-solid border-0 border-t border-brand-gray-brand">
            <DataTable
              dataset={dataset}
              filters={selectedFilters}
              onMultiSelect={onMultiSelectHandler}
              multiSelectIdentifierKey="imei"
              columnConfig="2rem 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"
              columns={[
                { key: "imei", title: "IMEI" },
                { key: "account", title: "Konto" },
                { key: "model", title: "Model" },
                { key: "productCode", title: "Produktkode" },
                { key: "deviceTypeName", title: "Modultype" },
                { key: "shipment", title: "Forsendelse" },
                { key: "simicc", title: "SIMICC" },
                { key: "activityStatus", title: "Status" },
                {
                  key: "createdAt",
                  title: "Batchdato",
                  formatData: (timestamp) =>
                    format(new Date(timestamp), "dd-MM-yyyy HH:mm"),
                },
              ]}
            />
          </div>
        </>
      )}
    </MainContainer>
  )
}

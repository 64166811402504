import React from "react"

export type FlowScreenType = {
  key: string
  title?: string
  children: React.ReactNode
  onNextScreen?: (...args) => {}
  onPrevScreen?: (...args) => {}
  onGoToScreen?: (...args) => {}
  wrapper?: React.ReactElement
}

type FlowType = {
  screenSet: FlowScreenType[]
}

export const Flow: React.FC<FlowType> = ({ screenSet, ...props }) => {
  return screenSet.map((screen, i) => {
    const { key, title, children, wrapper, ...rest } = screen
    if (!wrapper) return children
    return (
      <wrapper.type key={key} {...wrapper.props} {...rest}>
        {children}
      </wrapper.type>
    )
  })
}

import React, { useContext, useMemo, useState } from "react"
import "twin.macro"
import { AccountsContext } from "app/Account/context"
import { MainContainer } from "app/MainContainer"
import { columnMap, accountFilterGroups } from "app/Account/helper"
import { DataTable } from "app/DataTable"
import { Account } from "app/Account/types"
import { AccountTypes, AccountsActions } from "app/Account/actions"
import { navigate } from "gatsby"
import { Filter } from "app/DataTable/Filter"
import { CollectionFilter } from "utils/collection/filter"
import { SearchProvider } from "app/Search/context"
import { Button, Icon } from "@clevertrack/shared"
import { IconSizeEnum } from "lib/Icon"
import PopOver from "app/PopOver"
import { useAccount, useAccounts } from "app/Account/hooks"
import { Select } from "lib/Select"
import cogoToast from "@clevertrackdk/cogo-toast"
import { useFirestoreCompany } from "services/firestore/company"
import { useBackgroundDataFetch } from "app/Dashboard/hooks"
import { usePermissions } from "app/FeatureComponent/hooks"
import { UserTypeEnum } from "app/User/types"

type AccountsType = {
  onlyAssignedAccounts?: boolean
}

export const Accounts: React.FC<AccountsType> = ({
  onlyAssignedAccounts = false,
  ...props
}) => {
  const {
    state: { account, accounts },
    dispatch,
  } = useContext(AccountsContext)
  useAccount()
  const { availableAccounts, assignedAccounts } = useAccounts()
  const { hasAccess } = usePermissions()
  const {
    assignAccountsToFirebaseCompany,
    unassignAccountsToFirebaseCompany,
  } = useFirestoreCompany()
  const { getApiAccounts } = useBackgroundDataFetch()
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [selectedFilters, setSelectedFilters] = useState<CollectionFilter[]>([])

  const onSelectAccountHandler = (account: Account) => {
    dispatch(
      AccountsActions(AccountTypes.SetAccount, {
        account: account,
      })
    )
    navigate(`/app/accounts/${account.id}/devices`)
  }

  const onMultiSelectHandler = (rows) => {
    setSelectedRows(rows)
  }

  const onAssignAccountsToAccount = async () => {
    try {
      if (selectedAccount) {
        const accounts = selectedRows.map((row) => {
          return {
            account: row.name,
            accountID: row.id,
          }
        })

        const result = await assignAccountsToFirebaseCompany(
          accounts,
          selectedAccount.id
        )
        if (result === "OK") {
          cogoToast.success(
            `${accounts.length} konti tildelt til '${selectedAccount.name}`
          )
          setSelectedAccount(null)
          setSelectedRows([])
          getApiAccounts()
        }
      }
    } catch (error) {
      cogoToast.error("Kunne ikke tildele konti")
    }
  }

  const onUnassignAccountsToAccount = async () => {
    try {
      if (account.id) {
        const accounts = selectedRows.map((row) => {
          return {
            account: row.name,
            accountID: row.id,
          }
        })

        const result = await unassignAccountsToFirebaseCompany(
          accounts,
          account.id
        )
        if (result === "OK") {
          cogoToast.success(
            `${accounts.length} konti ikke længere tildelt til '${account.name}`
          )
          setSelectedAccount(null)
          setSelectedRows([])
          getApiAccounts()
        }
      }
    } catch (error) {
      cogoToast.error("Kunne ikke fjerne tildeling af konti")
    }
  }

  return (
    <MainContainer
      header={
        <div tw="flex items-center ">
          <h2>
            {onlyAssignedAccounts ? `Konti tildelt ${account?.name}` : "Konti"}
          </h2>
          {!onlyAssignedAccounts && (
            <Button
              type="button"
              variant="primary"
              icon="right"
              size="sm"
              tw="ml-8"
              onClick={() => navigate("/app/accounts/create")}
            >
              <span>Opret ny konto</span>
              <Icon icon="plus" size={IconSizeEnum.SM} />
            </Button>
          )}
        </div>
      }
    >
      <SearchProvider>
        <Filter
          filterGroups={accountFilterGroups}
          onFilterUpdated={(filter) => setSelectedFilters(filter)}
        />
        <PopOver
          tw="z-100 py-4 px-8 bottom-0 left-0 flex items-center absolute bg-white shadow-none overflow-visible"
          show={selectedRows.length > 0}
          selector="#dataTableFooter"
        >
          {!onlyAssignedAccounts && (
            <div tw="w-1/5 mr-8">
              <Select
                tw="bg-brand-gray-brand"
                placeholder="Vælg en konto"
                options={availableAccounts}
                onChange={(opt) => setSelectedAccount(opt.value)}
                menuPlacement="auto"
              />
            </div>
          )}
          <Button
            type="button"
            variant="default"
            icon="right"
            size="sm"
            onClick={
              onlyAssignedAccounts
                ? onUnassignAccountsToAccount
                : onAssignAccountsToAccount
            }
          >
            <span>
              {onlyAssignedAccounts ? `Fjern tildeling af` : `Tildel`}{" "}
              {selectedRows.length} kont
              {selectedRows.length > 1 ? "i" : "o"} til{" "}
              {selectedAccount
                ? selectedAccount.name
                : account && onlyAssignedAccounts
                ? account.name
                : "konto"}
            </span>
            <Icon icon="key" size={IconSizeEnum.SM} />
          </Button>
        </PopOver>
        <DataTable
          columns={columnMap}
          columnConfig="2rem 4rem 2fr 1fr 1.5fr 4rem 1fr 1fr 1fr 1fr 6rem 6rem"
          multiSelectIdentifierKey="id"
          onMultiSelect={
            hasAccess([UserTypeEnum.CMSADMIN])
              ? onMultiSelectHandler
              : () => ({})
          }
          filters={selectedFilters}
          dataset={onlyAssignedAccounts ? assignedAccounts : accounts}
          searchKeys={["name", "id", "vatNumber"]}
          rowSelectKey="name"
          onRowSelect={onSelectAccountHandler}
          tw="px-8"
        />
      </SearchProvider>
    </MainContainer>
  )
}

import React, { useMemo } from "react"
import tw from "twin.macro"
import { Tag } from "@clevertrack/shared"
import { UserTypeEnum, UserTypes } from "app/User/types"
import styled from "styled-components"

const colorThemes = {
  Admin: tw`bg-brand-blue-300`,
  CmsAdmin: tw`bg-brand-500`,
  User: tw`bg-brand-gray-base`,
  Fitter: tw`bg-brand-green-200`,
  Retailer: tw`bg-brand-green-300`,
}

const StyledTag = styled(Tag)`
  ${tw`max-w-max`}
`

export const UserType: React.FC<UserTypes> = ({ ...props }) => {
  return (
    <span tw="flex space-x-2">
      {props.userTypes.map((type) => (
        <StyledTag show css={colorThemes[type]}>
          {type}
        </StyledTag>
      ))}
    </span>
  )
}

import React, { useContext, useMemo, useState } from "react"
import "twin.macro"
import { MainContainer } from "app/MainContainer"
import { DataTable } from "app/DataTable"
import { StockType } from "app/DataTable/components/StockType"
import { Filter } from "app/DataTable/Filter"
import { deviceFilterGroups } from "app/Devices/helper"
import { CollectionFilter } from "utils/collection/filter"
import uniq from "lodash-es/uniq"
import { DevicesContext } from "app/Devices/context"
import { SearchProvider } from "app/Search/context"
import { navigate } from "gatsby"
import { IconSizeEnum } from "lib/Icon"
import { Button, Icon } from "@clevertrack/shared"
import format from "date-fns/format"
import { Select } from "lib/Select"
import { AccountsContext } from "app/Account/context"
import PopOver from "app/PopOver"
import cogoToast from "@clevertrackdk/cogo-toast"
import { useFirestoreDevices } from "services/firestore/devices"
import { useBackgroundDataFetch } from "app/Dashboard/hooks"
import { useAccounts } from "app/Account/hooks"

export const Devices: React.FC = ({ ...props }) => {
  const {
    state: { devices },
  } = useContext(DevicesContext)
  const {
    state: { accounts },
  } = useContext(AccountsContext)
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [selectedFilters, setSelectedFilters] = useState<CollectionFilter[]>([])
  const { availableAccounts } = useAccounts()
  const { assignFirebaseDevicesToAccount } = useFirestoreDevices()
  const { getApiDevices } = useBackgroundDataFetch()

  const availableDeviceFilterGroups = useMemo(() => {
    const availableDeviceTypes = uniq(devices.map((x) => x.deviceTypeName))

    return deviceFilterGroups.map((group) => {
      if (group.dataKey === "deviceType") {
        return {
          ...group,
          dataKey: "deviceTypeName",
          options: group.options.filter((x) =>
            availableDeviceTypes.includes(x.label)
          ),
        }
      }
      return group
    })
  }, [devices, deviceFilterGroups])

  const onMultiSelectHandler = (rows) => {
    setSelectedRows(rows)
  }

  const onAssignDevicesToAccount = async () => {
    try {
      if (selectedAccount) {
        const devices = selectedRows.map((row) => {
          return {
            ...row,
            account: selectedAccount.name,
            accountID: selectedAccount.id,
          }
        })

        const result = await assignFirebaseDevicesToAccount(devices)
        if (result === "OK") {
          cogoToast.success(
            `${devices.length} enheder tildelt til '${selectedAccount.name}`
          )
          setSelectedAccount(null)
          setSelectedRows([])
          getApiDevices()
        }
      }
    } catch (error) {
      cogoToast.error("Kunne ikke tildele enheder til konto")
    }
  }

  return (
    <MainContainer
      header={
        <>
          <h2>Enheder</h2>
          <Button
            type="button"
            variant="primary"
            icon="right"
            size="sm"
            tw="ml-8"
            onClick={() => navigate("/app/devices/create")}
          >
            <span>Opret enheder</span>
            <Icon icon="plus" size={IconSizeEnum.SM} />
          </Button>
        </>
      }
    >
      <SearchProvider>
        <Filter
          filterGroups={availableDeviceFilterGroups}
          filterByLabel
          onFilterUpdated={(filter) => setSelectedFilters(filter)}
          tw="relative z-80"
        />
        <PopOver
          tw="z-100 py-4 px-8 bottom-0 left-0 flex items-center absolute bg-white shadow-none overflow-visible"
          show={selectedRows.length > 0}
          selector="#dataTableFooter"
        >
          <div tw="w-1/5">
            <Select
              tw="bg-brand-gray-brand"
              placeholder="Vælg en konto"
              options={availableAccounts}
              onChange={(opt) => setSelectedAccount(opt.value)}
              menuPlacement="auto"
            />
          </div>
          <Button
            type="button"
            variant="default"
            icon="right"
            size="sm"
            tw="ml-8"
            onClick={onAssignDevicesToAccount}
          >
            <span>
              Tildel {selectedRows.length} enheder til{" "}
              {selectedAccount ? selectedAccount.name : "konto"}
            </span>
            <Icon icon="key" size={IconSizeEnum.SM} />
          </Button>
        </PopOver>
        <DataTable
          id="dataTable"
          columns={[
            { key: "id", title: "ID" },
            {
              key: "account",
              title: "Konto",
            },
            { key: "stockStatus", title: "Lagerstatus", component: StockType },
            { key: "deviceCategory", title: "Type" },
            { key: "deviceTypeName", title: "Modul" },
            { key: "imei", title: "IMEI" },
            { key: "simicc", title: "SIMICC" },
            {
              key: "created",
              title: "Oprettet",
              formatData: (d) => format(new Date(d), "dd-MM-yyyy"),
            },
          ]}
          columnConfig="2rem 6rem 1fr 10rem 8rem 1fr 1fr 1fr 8rem"
          multiSelectIdentifierKey="IMEI"
          onMultiSelect={onMultiSelectHandler}
          filters={selectedFilters}
          dataset={devices}
          itemsPerPage={300}
          searchKeys={["id", "account", "imei", "simicc"]}
          // onRowSelect={onSelectAccountHandler}
          tw="px-8 relative z-30"
        />
      </SearchProvider>
    </MainContainer>
  )
}

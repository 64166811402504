import React, { useContext, useRef, useLayoutEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import "twin.macro"
import { useLocation } from "@reach/router"
import OffCanvas from "lib/OffCanvas"
import { AppContext, NavigationContext } from "context"
import { useLockBodyScroll } from "hooks/useLockBodyScroll"
import { Logo } from "@clevertrack/shared"
import { GlobalAppActions, GlobalAppTypes } from "context/App/actions"
import { Link } from "gatsby"
import tw from "twin.macro"

const StyledOffCanvas = styled(OffCanvas)`
  right: 10vw;
  z-index: 20000;
  position: fixed;
  bottom: 0;
`

const StyledOffCanvasNavigation = styled.div`
  width: 90vw;
  height: 100vh;
  background: ${(props) => props.theme.colors.white};
  position: relative;
  z-index: 400;
  overflow: hidden;
  overflow-y: scroll;
  // padding-bottom: 10rem;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    background: #fff;
    border-radius: 1rem;
    width: 5px;
    padding: 2px 0 2px 0;
  }
  &::-webkit-scrollbar-button {
  }
  &::-webkit-scrollbar-track {
    /* 3 */
    background: #eee;
    border-radius: 1rem;
    padding: 2px 2px 2px 0;
  }
  &::-webkit-scrollbar-track-piece {
    /* 4 */
  }
  &::-webkit-scrollbar-thumb {
    /* 5 */
    background: #999;
    border-radius: 1rem;
    margin: 2px;
  }
  &::-webkit-scrollbar-corner {
    /* 6 */
  }
  &::-webkit-resizer {
    /* 7 */
  }
`

const StyledHeader = styled.header`
  position: sticky;
  z-index: 200;
  top: 1px;
  margin: 0;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.colors.grayLight};
  background: ${(props) => props.theme.colors.white};

  .logo {
    display: flex;
    align-items: center;
    font-family: ${(props) => props.theme.fontStacks.bold};
    // height: 4rem;
    ${tw`m-2`}

    span {
      font-size: 2.4rem;
      margin-top: 0.5rem;
      ${tw`block ml-4`}
    }

    img {
      align-self: center;
      line-height: 0;
      width: 4rem;
      height: 4rem;
    }
  }
`

const StyledFooter = styled.footer`
  margin-top: auto;
  align-self: flex-end;
  width: 100%;
`

const StyledNavInner = styled.nav`
  padding-bottom: 2rem;
`

const OffCanvasNav: React.FC = ({ nav, navToggle, children }) => {
  const {
    state: { navToggled },
    dispatch,
  } = useContext(AppContext)
  const offCanvasRef = useRef(null)
  useLockBodyScroll(navToggled)
  // useOnClickOutside(offCanvasRef, onDismiss)
  const location = useLocation()

  useLayoutEffect(() => {
    if (navToggled)
      dispatch(GlobalAppActions(GlobalAppTypes.ToggleNav, { toggled: false }))
  }, [location.pathname])

  return (
    <StyledOffCanvas selector="#___offcanvas" show={navToggled} fromLeft>
      <StyledOffCanvasNavigation ref={offCanvasRef}>
        <StyledHeader>
          <Link to="/app" className="logo">
            <img src="/gradient-logomark.svg" />
            <span>Hub</span>
          </Link>

          {navToggle}
        </StyledHeader>
        <StyledNavInner>{nav}</StyledNavInner>
        <StyledFooter>{children}</StyledFooter>
      </StyledOffCanvasNavigation>
    </StyledOffCanvas>
  )
}

export default OffCanvasNav

OffCanvasNav.defaultProps = {
  children: null,
  onToggle: () => {},
}

OffCanvasNav.propTypes = {
  onToggle: PropTypes.func,
  navToggle: PropTypes.node.isRequired,
  nav: PropTypes.node.isRequired,
  children: PropTypes.node,
}

import { ClientApiAccount, Account, BasicApiAccount } from "app/Account/types"
import { ApiVehicle } from "app/Devices/types"
import { privateApi } from "utils/api"

/**
 * Fetch a list of accounts
 */
type GetAccountsResponse = {
  result: string
  count: number
  companys: ClientApiAccount[]
}

type PostAccountsResponse = {
  result: string
  count: number
  companys: ClientApiAccount[]
}

type GetAccountVehiclesResponse = {
  result: string
  count: number
  vehicles: any[]
}

export type AccountsResponse = {
  result: string
  count?: number
  accounts?: Account[] | ClientApiAccount[]
  error?: unknown
}

export type AccountVehiclesResponse = {
  result: string
  count?: number
  accountVehicles?: ApiVehicle[]
  error?: unknown
}

const getAccounts = async (): Promise<AccountsResponse> => {
  try {
    const {
      data: { result, count, companys },
    } = await privateApi().get<GetAccountsResponse>("/Company")
    return {
      count,
      result,
      accounts: companys.map(({ contact_phone, ...rest }) => {
        return {
          ...rest,
          contactPhone: contact_phone,
        } as Account
      }),
    }
  } catch (e) {
    return {
      result: "error",
      error: e,
    }
  }
}

const getAccountVehicles = async (
  accountID
): Promise<AccountVehiclesResponse> => {
  try {
    const {
      data: { result, count, vehicles },
    } = await privateApi().get<GetAccountVehiclesResponse>(
      `/vehicles/${accountID}/cms`
    )
    return {
      count,
      result,
      accountVehicles: vehicles,
    }
  } catch (e) {
    return {
      result: "error",
      error: e,
    }
  }
}

const getCustomerType = async () => {
  try {
    const { data } = await privateApi().get(`/CmsCustomerType`)
    return {}
  } catch {
    return {
      result: "error",
      error: e,
    }
  }
}

const createAccount = async (
  account: ClientApiAccount
): Promise<AccountsResponse> => {
  try {
    const {
      data: { result, count, companys },
    } = await privateApi().post<PostAccountsResponse>(`/Company`, account)
    return { result, count, accounts: companys }
  } catch (error) {
    return {
      result: "error",
      error,
    }
  }
}

const saveAccount = async (account: ClientApiAccount) => {
  try {
    const res = await privateApi().patch(`/Company/${account.id}`, account)
    return res
  } catch (error) {
    return {
      result: "error",
      error: e,
    }
  }
}

export {
  getAccounts,
  getAccountVehicles,
  getCustomerType,
  saveAccount,
  createAccount,
}

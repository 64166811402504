import React, { useCallback, useContext, useMemo, useState } from "react"
import QrReader from "react-qr-scanner"
import { isIMEI } from "validator"
import PopOver from "app/PopOver"
import tw from "twin.macro"
import { InstallationContext } from "./context"
import { useLockBodyScroll } from "hooks/useLockBodyScroll"
import { DevicesContext } from "app/Devices/context"
import { DataList } from "app/DataList"
import { Search } from "app/Search"
import { freetextSearch } from "app/Search/helper"
import { SearchContext } from "app/Search/context"
import {
  Beacon,
  DeviceStockStatusEnum,
  StockDevice,
  Tracker,
} from "app/Devices/types"
import { Icon, Button } from "@clevertrack/shared"
import { IconSizeEnum } from "lib/Icon"
import { SearchActions, SearchTypes } from "app/Search/actions"
import styled from "styled-components"
import { InstallationActions, InstallationTypes } from "./actions"
import { useAccounts } from "app/Account/hooks"
import { useUser } from "app/User/hooks"
import {
  generateDeviceCalibrationStep,
  generateDeviceInformationStep,
  generateInstallationVerificationStep,
} from "./Flow/helper"
import {
  createVehicle,
  getVehicleCalibration,
  getVehicleFeatures,
} from "services/vehicles"
import { useFirestoreDevices } from "services/firestore/devices"
import { useInstallation } from "./hooks"
import { useFirebaseFunctions } from "services/firebase-functions/functions"
import { InstallationScreenEnum, InstallationStep } from "./types"

const StyledQrPopover = styled.div`
  ${tw`bg-white w-full h-full flex flex-col items-center justify-center`}

  .overlay {
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 100;
    ${tw`flex flex-col items-center justify-center`}

    svg {
      width: 80vw;
      height: 80vw;
      margin-top: 4.5rem;
      margin-bottom: 4rem;
      fill: white;
      opacity: 0.6;
    }
  }

  .qr-wrapper {
    width: 90vw;
    height: 90vw;
    position: absolute;
    z-index: 50;

    video {
      margin-top: -3rem;
      width: 90vw;
      height: 90vw;
      object-fit: cover;
    }
  }
`

export const Installation: React.FC = ({ ...props }) => {
  const {
    state: { account, vehicles, accountDevices },
    dispatch,
  } = useContext(InstallationContext)
  const {
    state: { results, query, suggestions },
    dispatch: searchDispatch,
  } = useContext(SearchContext)
  const {
    state: { devices },
  } = useContext(DevicesContext)
  useLockBodyScroll(true)
  const {
    saveFirebaseDevice,
    getInstallationStepsByImei,
    saveInstallationStepByImei,
  } = useFirestoreDevices()
  const { assignedAccounts } = useAccounts()
  const { getAccountApiVehicles } = useInstallation()

  const { currentUserIsExternalFitter } = useUser()
  const [qrScanToggled, setQrScanToggled] = useState(false)

  useMemo(() => {
    if (account?.id) getAccountApiVehicles(account.id)
  }, [account])

  const datasetSearch = useMemo(() => {
    if (accountDevices.length === 0) return null
    return freetextSearch(accountDevices, {
      threshold: 0.3,
      location: 0,
      distance: 100,
      keys: ["imei", "name", "deviceTypeName"],
      includeScore: true,
    })
  }, [accountDevices])

  const onScanQRHandler = useCallback((result) => {
    if (result && isIMEI(result.text)) {
      setQrScanToggled(false)
      searchDispatch(
        SearchActions(SearchTypes.SetQuery, { query: result.text })
      )
    }
  }, [])

  const alternateAccount = useMemo(() => {
    if (results.length === 0 && query.length > 0) {
      const findDevice = devices.find((dev) => dev.imei === query)
      if (findDevice) {
        return assignedAccounts.find((acc) => acc.id === findDevice.accountID)
      }
      return null
    }
    return null
  }, [devices, results, query, assignedAccounts])

  const switchAccount = async (account) => {
    dispatch(InstallationActions(InstallationTypes.SetAccount, { account }))
  }

  const resolveInstallationSteps = async (device) => {
    if (device?.imei) {
      const savedSteps = (await getInstallationStepsByImei(
        device.imei
      )) as InstallationStep[]

      const deviceInfoStep = savedSteps.find(
        (step) => step.stepID === InstallationScreenEnum.DeviceInformation
      )
      const installationVerificationStep = savedSteps.find(
        (step) =>
          step.stepID === InstallationScreenEnum.InstallationVerification
      )
      const deviceCalibrationStep = savedSteps.find(
        (step) => step.stepID === InstallationScreenEnum.DeviceCalibration
      )

      const steps = [
        deviceInfoStep
          ? {
              ...deviceInfoStep,
              data: {
                ...generateDeviceInformationStep(device).data,
                ...deviceInfoStep.data,
              },
            }
          : generateDeviceInformationStep(device),
        installationVerificationStep
          ? {
              ...installationVerificationStep,
              data: {
                ...generateInstallationVerificationStep(device).data,
                ...installationVerificationStep.data,
              },
            }
          : generateInstallationVerificationStep(device),
        deviceCalibrationStep
          ? {
              ...deviceCalibrationStep,
              data: {
                ...generateDeviceCalibrationStep(device).data,
                ...deviceCalibrationStep.data,
              },
            }
          : generateDeviceCalibrationStep(device),
      ]

      if (savedSteps.length === 0) {
        // Fresh item, load up defaults into firestore
        await Promise.all([
          saveInstallationStepByImei(
            device?.imei,
            InstallationScreenEnum.DeviceInformation,
            steps.find(
              (s) => s.stepID === InstallationScreenEnum.DeviceInformation
            )
          ),
          saveInstallationStepByImei(
            device?.imei,
            InstallationScreenEnum.DeviceCalibration,
            steps.find(
              (s) => s.stepID === InstallationScreenEnum.DeviceCalibration
            )
          ),
          saveInstallationStepByImei(
            device?.imei,
            InstallationScreenEnum.InstallationVerification,
            steps.find(
              (s) =>
                s.stepID === InstallationScreenEnum.InstallationVerification
            )
          ),
        ])
      }

      dispatch(
        InstallationActions(InstallationTypes.SetInstallationSteps, {
          steps,
        })
      )
      return
    }

    dispatch(
      InstallationActions(InstallationTypes.SetInstallationSteps, {
        steps: null,
      })
    )
  }

  const onSelectDeviceHandler = async (item) => {
    if (
      item.stockStatus === DeviceStockStatusEnum.Stock &&
      account?.id &&
      item.unit_id
    ) {
      const res = await createVehicle(
        { unit_id: item.unit_id, name: item.imei },
        account.id
      )
      if (account?.id) getAccountApiVehicles(account.id)
      if (res.result === "OK") {
        await saveFirebaseDevice(
          {
            stockStatus: DeviceStockStatusEnum.Awaiting,
          },
          item.imei.toString()
        )
      }
    }

    if (item.imei) {
      const accountVehicle = vehicles.find((dvc) => +dvc.imei === +item.imei)
      const apiCalibReq = getVehicleCalibration(accountVehicle?.id ?? item.id)
      const apiFeatureReq = getVehicleFeatures(accountVehicle?.id ?? item.id)
      const [apiDeviceCalibration, apiFeatures] = await Promise.all([
        apiCalibReq,
        apiFeatureReq,
      ])
      const itemWithMeta = { ...accountVehicle, ...item }

      if (
        apiDeviceCalibration.result === "OK" &&
        apiDeviceCalibration.vehicleCalibrationData
      ) {
        itemWithMeta.calibration = apiDeviceCalibration.vehicleCalibrationData
      }

      if (apiFeatures.result === "OK" && apiFeatures.features) {
        itemWithMeta.features = apiFeatures.features
      }

      await resolveInstallationSteps(itemWithMeta)

      dispatch(
        InstallationActions(InstallationTypes.SetDevice, {
          device: itemWithMeta,
        })
      )
      return
    }

    await resolveInstallationSteps(item)

    dispatch(InstallationActions(InstallationTypes.SetDevice, { device: item }))
  }

  const onSelectNewAccountHandler = () => {
    dispatch(InstallationActions(InstallationTypes.ClearAccount))
    dispatch(
      InstallationActions(InstallationTypes.SetAccountDevices, {
        accountDevices: [],
      })
    )
  }

  const [
    stockDevices,
    awaitingDevices,
    installedDevices,
    unverifiedDevices,
  ] = useMemo(() => {
    if (datasetSearch && query.length >= 2) {
      const searchResults = datasetSearch
        .search(query)
        .map((x) => x.item) as Partial<(Beacon | Tracker) & StockDevice[]>
      return [
        searchResults.filter(
          (x) => x.stockStatus === DeviceStockStatusEnum.Stock
        ),
        searchResults.filter(
          (x) => x.stockStatus === DeviceStockStatusEnum.Awaiting
        ),
        searchResults.filter(
          (x) => x.stockStatus === DeviceStockStatusEnum.Installed
        ),
        searchResults.filter((x) => !x.stockStatus),
      ]
    }
    return [
      accountDevices.filter(
        (x) => x.stockStatus === DeviceStockStatusEnum.Stock
      ),
      accountDevices.filter(
        (x) => x.stockStatus === DeviceStockStatusEnum.Awaiting
      ),
      accountDevices.filter(
        (x) => x.stockStatus === DeviceStockStatusEnum.Installed
      ),
      accountDevices.filter((x) => !x.stockStatus),
    ]
  }, [query, accountDevices, datasetSearch])

  return (
    <PopOver
      fromBottom
      show={account !== null}
      zindex={2000}
      selector="#___wrapper"
      tw="absolute shadow-none overflow-hidden"
    >
      <div tw="w-full h-full overflow-y-scroll bg-white">
        <header tw="p-8 pb-4 sticky top-0 bg-white z-50">
          <span tw="block mb-2">Monterer for</span>
          <span tw="text-2xl font-bold flex items-baseline justify-between">
            <span>{account?.name}</span>
            {!currentUserIsExternalFitter && (
              <span
                tw="text-brand-500 flex items-center"
                onClick={onSelectNewAccountHandler}
              >
                <Icon icon="chevron-left" tw="w-4 h-4 mr-2" />
                <span tw="text-lg font-normal">Vælg en anden konto</span>
              </span>
            )}
          </span>
          {datasetSearch && (
            <div tw="bg-white mt-8 flex items-center">
              <div tw="flex-1">
                <Search dataset={datasetSearch} placeholder="Søg efter enhed" />
              </div>
              <Button
                variant="icon"
                invert
                tw="p-0 w-12 h-12 m-0 ml-4 -mt-4"
                onClick={() => setQrScanToggled(true)}
              >
                <span tw="flex flex-col items-center">
                  <Icon
                    icon="qrcode"
                    size={IconSizeEnum.AUTO}
                    tw="text-brand-black-base w-12 h-12"
                  />
                  <span tw="text-sm block text-nowrap -mt-2">Scan QR</span>
                </span>
              </Button>
              <PopOver
                fromBottom
                show={qrScanToggled}
                zindex={2100}
                tw="overflow-y-hidden"
              >
                <StyledQrPopover>
                  <div className="overlay">
                    <h3 tw="m-0">Scan enhedens QR-kode (beta)</h3>
                    <Icon icon="expand" />

                    <Button
                      variant="cancel"
                      onClick={() => setQrScanToggled(false)}
                    >
                      Annullér
                    </Button>
                    <span tw="block">
                      <strong>Tips: </strong>Hold en finger over S/N QR-koden.
                    </span>
                    <span tw="block">Funktionen virker bedst på iPhone.</span>
                  </div>
                  <div className="qr-wrapper">
                    {qrScanToggled && (
                      <QrReader
                        key="environment"
                        onScan={onScanQRHandler}
                        constraints={{
                          facingMode: "environment",
                          video: {
                            facingMode: "environment",
                          },
                        }}
                        legacyMode={true}
                      />
                    )}
                  </div>
                </StyledQrPopover>
              </PopOver>
            </div>
          )}
        </header>
        {stockDevices.length > 0 && (
          <>
            <h4 tw="p-8 m-0 sticky top-52 bg-white z-50">Lager</h4>
            <DataList
              items={stockDevices}
              titleKey="name"
              descriptionKey={["imei", "deviceTypeName"]}
              onItemSelect={onSelectDeviceHandler}
            />
          </>
        )}
        {awaitingDevices.length > 0 && (
          <>
            <h4 tw="p-8 m-0 sticky top-52 bg-white z-50">Montering i gang</h4>
            <DataList
              items={awaitingDevices}
              titleKey="name"
              descriptionKey={["imei", "deviceTypeName"]}
              onItemSelect={onSelectDeviceHandler}
            />
          </>
        )}
        {installedDevices.length > 0 && (
          <>
            <h4 tw="p-8 m-0 sticky top-52 bg-white z-50">Færdigmonteret</h4>
            <DataList
              items={installedDevices}
              titleKey="name"
              descriptionKey={["imei", "deviceTypeName"]}
              onItemSelect={onSelectDeviceHandler}
            />
          </>
        )}
        {unverifiedDevices.length > 0 && (
          <>
            <h4 tw="p-8 m-0 sticky top-52 bg-white z-50">
              Ikke verificerede i Hub
            </h4>
            <DataList
              items={unverifiedDevices}
              titleKey="name"
              descriptionKey={["imei", "deviceTypeName"]}
              onItemSelect={onSelectDeviceHandler}
            />
          </>
        )}
        {results.length === 0 &&
        suggestions.length === 0 &&
        query.length > 0 ? (
          <div tw="text-center p-8">
            <p>Enheden findes ikke på {account?.name}</p>
            {alternateAccount && (
              <>
                <p tw="text-xl">
                  Enheden med IMEI {query} findes på {alternateAccount.name}
                </p>
                <Button
                  variant="default"
                  size="sm"
                  onClick={() => switchAccount(alternateAccount)}
                >
                  Skift til {alternateAccount.name}
                </Button>
              </>
            )}
          </div>
        ) : null}
      </div>
    </PopOver>
  )
}

import React, { useRef, useState } from "react"
import { useClickAway } from "react-use"
import "twin.macro"
import { Button, Dropdown } from "@clevertrack/shared"
import Icon, { IconSizeEnum } from "lib/Icon"

export const ContextMenu: React.FC = ({ children, ...props }) => {
  const contextMenuRef = useRef(null)
  const [contextMenuToggled, setContextMenuToggled] = useState(false)
  useClickAway(contextMenuRef, () => setContextMenuToggled(false))
  return (
    <div tw="relative ml-8" ref={contextMenuRef}>
      <Button
        type="button"
        variant="icon"
        onClick={() => setContextMenuToggled((prev) => !prev)}
      >
        <Icon icon="ellipsis-h" size={IconSizeEnum.MD} />
      </Button>
      <Dropdown toggled={contextMenuToggled} tw="right-auto left-0">
        {children}
      </Dropdown>
    </div>
  )
}

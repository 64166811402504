import { useContext } from "react"
import { UserContext } from "app/User/context"
import { getAccounts } from "services/accounts"
import { getDevices } from "services/devices"
import { AccountsContext } from "app/Account/context"
import { AccountTypes, AccountsActions } from "app/Account/actions"
import { DevicesContext } from "app/Devices/context"
import { DevicesActions, DevicesTypes } from "app/Devices/actions"
import { UserActions, UserActionTypes } from "app/User/actions"
import { getUsers } from "services/users"
import { UserTypeEnum } from "../User/types"
import { useFirestoreCompany } from "services/firestore/company"
import { useFirestoreUser } from "services/firestore/user"
import { useFirestoreDevices } from "services/firestore/devices"
import {
  Beacon,
  DeviceCategoryEnum,
  StockDevice,
  Tracker,
} from "app/Devices/types"
import { ITapBarItem } from "lib/Navigation/navigation.types"
import { getAccountVehicles } from "services/vehicles"
import { useFirebaseFunctions } from "services/firebase-functions/functions"
import cogoToast from "@clevertrackdk/cogo-toast"

export const useBackgroundDataFetch = () => {
  const {
    state: { user },
    dispatch: usersDispatch,
  } = useContext(UserContext)
  const {
    state: { account },
    dispatch: accountsDispatch,
  } = useContext(AccountsContext)
  const { dispatch: devicesDispatch } = useContext(DevicesContext)
  const { getFirebaseCompanies } = useFirestoreCompany()
  const { getAllFirebaseUsers } = useFirestoreUser()
  const { getFirebaseDevices } = useFirestoreDevices()
  const { getFotaConfigurationFiles } = useFirebaseFunctions()

  const getApiAccounts = async () => {
    const accountsResponse = await getAccounts()

    if (accountsResponse.result === "OK" && accountsResponse?.accounts) {
      const fbAccounts = await getFirebaseCompanies()
      const mappedAccounts = accountsResponse.accounts.map((acc) => {
        const fbAccount = fbAccounts.find((x) => +x.id === +acc.id)
        if (fbAccount) {
          return {
            ...acc,
            ...fbAccount,
            mapLatitude: fbAccount.map?.center[0],
            mapLongitude: fbAccount.map?.center[1],
            defaultMapZoom: fbAccount.map?.defaultZoom,
          }
        }
        return acc
      })
      accountsDispatch(
        AccountsActions(AccountTypes.SetAccounts, {
          accounts: mappedAccounts,
        })
      )
    }
  }

  const getApiUsers = async () => {
    const usersResponse = await getUsers()

    if (usersResponse.result === "OK" && usersResponse?.users) {
      const firebaseUsers = await getAllFirebaseUsers()
      const mappedUsers = usersResponse.users.map((user) => {
        const fbUser = firebaseUsers
          ? firebaseUsers.find((u) => +u.id === +user.id)
          : {}
        const userTypes: UserTypeEnum[] = fbUser?.userTypes
          ? [...fbUser.userTypes]
          : []
        if (user.is_cms_admin) userTypes.push(UserTypeEnum.CMSADMIN)
        if (user.is_admin) userTypes.push(UserTypeEnum.ADMIN)
        // if (userTypes.length === 0) userTypes.push(UserTypeEnum.USER)

        return {
          ...user,
          userTypes,
        }
      })

      usersDispatch(
        UserActions(UserActionTypes.SetUserList, {
          users: mappedUsers,
        })
      )
    }
  }

  const getApiDevices = async () => {
    const devicesResponse = await getDevices()

    if (devicesResponse.result === "OK" && devicesResponse?.devices) {
      const firebaseDevices = (await getFirebaseDevices()) as StockDevice[]
      const mappedFirebaseDevices: Partial<
        (Beacon | Tracker) & StockDevice
      >[] = firebaseDevices.map((x) => {
        return {
          ...x,
          deviceTypeName: x.model,
          imei: x.imei,
          created: x.createdAt,
          deviceCategory: x.uuid
            ? DeviceCategoryEnum.Beacon
            : DeviceCategoryEnum.Tracker,
        }
      })
      devicesDispatch(
        DevicesActions(DevicesTypes.SetDevices, {
          devices: [...devicesResponse.devices, ...mappedFirebaseDevices],
        })
      )
    }
  }

  const getConfigurationFiles = async () => {
    try {
      const cfgFilesResponse = await getFotaConfigurationFiles()
      if (
        cfgFilesResponse.data.status === "OK" &&
        cfgFilesResponse.data.files.length > 0
      ) {
        devicesDispatch(
          DevicesActions(DevicesTypes.SetConfigurationFiles, {
            files: cfgFilesResponse.data.files,
          })
        )
      }
    } catch (e) {
      cogoToast.error("Kunne ikke indlæse enhedskonfigurationsfiler")
      throw new Error(e)
    }
  }

  const getData = async () => {
    await Promise.all([
      getApiAccounts(),
      getApiDevices(),
      getApiUsers(),
      getConfigurationFiles(),
    ])
  }

  return {
    getApiAccounts,
    getApiDevices,
    getApiUsers,
    getData,
    getConfigurationFiles,
  }
}

export const useNavigationItems = () => {
  const {
    state: { user },
  } = useContext(UserContext)

  const tapBarNavigationItems: ITapBarItem[] = [
    /* {
      key: "map",
      icon: "map",
      link: "/app",
      title: <I18n strKey="nav_start" />,
      featureFlag: FEATURE.START,
    },
    {
      key: "service",
      icon: "car-mechanic",
      link: "/app/service",
      title: <I18n strKey="nav_service" />,
      featureFlag: FEATURE.SERVICE,
      partiallyActive: true,
    },
    {
      key: "history",
      icon: "history",
      link: "/app/history",
      title: <I18n strKey="nav_history" />,
      featureFlag: FEATURE.HISTORY,
      partiallyActive: true,
    }, */
    {
      key: "nav_toggle",
      icon: "menu",
      title: "Menu",
      isNavToggle: true,
    },
  ]

  return { tapBarNavigationItems }
}

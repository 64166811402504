import React, { useContext } from "react"
import "twin.macro"
import PropTypes from "prop-types"
import styled from "styled-components"
import Icon from "lib/Icon"
import NavLink from "components/Navigation/NavLink"
import NavItem from "./NavItem"
import { FeatureComponent } from "app/FeatureComponent"
import tw from "twin.macro"
import { AccountsContext } from "app/Account/context"
import { UserTypeEnum } from "app/User/types"
/* import { navigate } from "gatsby"
import { AccountTypes, AccountsActions } from "app/Account/actions" */

const StyledNavigation = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  ${(props) => props.theme.media.tablet_landscape_up`
    display: flex;
    flex-direction: column;
    // align-items: flex-start;
    ${tw`h-full`}
  `}
`

function Navigation({ children, onSelectNavItem, ...props }) {
  const {
    state: { account },
    // dispatch,
  } = useContext(AccountsContext)

  /* const onRemoveHandler = () => {
    navigate("/app/accounts")
    dispatch(AccountsActions(AccountTypes.ClearAccount))
  } */

  return (
    <StyledNavigation {...props}>
      <FeatureComponent allowedUserRoles={[UserTypeEnum.CMSADMIN]}>
        <NavItem>
          <NavLink to="/app/accounts" activeClassName="active" snappy>
            <Icon icon="key" />
            <span>Konti</span>
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            to="/app/devices"
            activeClassName="active"
            snappy
            onClick={onSelectNavItem}
          >
            <Icon icon="microchip" />
            <span>Enheder</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/app/users" activeClassName="active" snappy>
            <Icon icon="person" />
            <span>Brugere</span>
          </NavLink>
        </NavItem>
      </FeatureComponent>
      {account && (
        <>
          <li tw="ml-8 mr-4 mt-8 opacity-60 block pb-2 mb-2 text-lg text-brand-gray-base truncate overflow-hidden">
            <span
              tw="text-lg"
              // onClick={onRemoveHandler}
              // tw="cursor-pointer hover:(text-brand-red-500 transition-all) text-lg text-ellipsis"
            >
              {account.name}
            </span>
          </li>
          <NavItem>
            <NavLink
              to={`/app/accounts/${account.id}/devices`}
              activeClassName="active"
              snappy
            >
              <Icon icon="microchip" />
              <span>Enheder</span>
            </NavLink>
          </NavItem>
          {account.assignedAccounts && account.assignedAccounts.length > 0 && (
            <NavItem>
              <NavLink
                to={`/app/accounts/${account.id}/assignedAccounts`}
                activeClassName="active"
                snappy
              >
                <Icon icon="key" />
                <span>Konti</span>
              </NavLink>
            </NavItem>
          )}
          <NavItem>
            <NavLink
              to={`/app/accounts/${account.id}/users`}
              activeClassName="active"
              snappy
            >
              <Icon icon="person" />
              <span>Brugere</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              to={`/app/accounts/${account.id}`}
              activeClassName="active"
              snappy
            >
              <Icon icon="edit" />
              <span>Stamdata</span>
            </NavLink>
          </NavItem>
        </>
      )}
      {children}
    </StyledNavigation>
  )
}

export default Navigation

Navigation.defaultProps = {
  children: null,
}

Navigation.propTypes = {
  children: PropTypes.node,
}

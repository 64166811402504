import { Flow } from "app/Flow"
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import "twin.macro"
import { Tasks } from "./Tasks"
import PopOver from "app/PopOver"
import { Responsive } from "@clevertrack/shared"
import { DataValidation } from "./DataValidation"
import { InstallationContext } from "../context"
import { InstallationActions, InstallationTypes } from "../actions"
import { DeviceCalibration } from "./DeviceCalibration"
import { DeviceInformation } from "./DeviceInformation"
import { DeviceHealthValidation } from "./DeviceHealthValidation"
import { InstallationVerification } from "./InstallationVerification"
import {
  InstallationScreenEnum,
  InstallationStep,
  InstallationStepStateEnum,
} from "../types"
import { useFirestoreDevices } from "services/firestore/devices"
import cogoToast from "@clevertrackdk/cogo-toast"
import { DeviceStockStatusEnum } from "app/Devices/types"
import { useRealtimeDeviceValues } from "services/realtime/vehicles"
import { useInstallation } from "../hooks"
import { DevicesContext } from "app/Devices/context"
import { DevicesActions, DevicesTypes } from "app/Devices/actions"

export const InstallationFlow: React.FC = ({ toggled, ...props }) => {
  const {
    state: { currentScreen, device, account, installationSteps },
    dispatch,
  } = useContext(InstallationContext)
  const { dispatch: devicesDispatch } = useContext(DevicesContext)
  const {
    getInstallationStepsByImei,
    saveInstallationStepByImei,
  } = useFirestoreDevices()
  const { getDeviceValuesByID } = useRealtimeDeviceValues()
  const { saveFirebaseDevice } = useFirestoreDevices()
  const { getAccountApiVehicles } = useInstallation()

  const setCurrentScreen = (screen: InstallationScreenEnum) => {
    dispatch(InstallationActions(InstallationTypes.SetScreen, screen))
  }

  /* const unsubscribeToDevice = useMemo(() => {
    if (device && device.id) {
      return getDeviceValuesByID(device?.id.toString(), (snapshot) =>
        dispatch(
          InstallationActions(InstallationTypes.SetCurrentDeviceValues, {
            currentDeviceValues: snapshot.val(),
          })
        )
      )
    }
  }, [device])

  useEffect(() => {
    if (!device && unsubscribeToDevice) {
      // unsubscribe
      unsubscribeToDevice()
    }
  }, [device, unsubscribeToDevice]) */

  useMemo(() => {
    if (!device?.imei) return
    if (
      !installationSteps.find((step) =>
        [
          InstallationStepStateEnum.NotStarted,
          InstallationStepStateEnum.Incomplete,
        ].includes(step.stepState)
      )
    ) {
      // Yay, installation completed!
      if (device?.stockStatus === DeviceStockStatusEnum.Awaiting) {
        saveFirebaseDevice(
          {
            stockStatus: DeviceStockStatusEnum.Installed,
          },
          device.imei.toString()
        ).then((res) => {
          if (res === "OK") {
            devicesDispatch(
              DevicesActions(DevicesTypes.UpdateDeviceByID, {
                device: {
                  ...device,
                  stockStatus: DeviceStockStatusEnum.Installed,
                },
              })
            )
          }
        })
      }
    } else {
      // Still work to do
      saveFirebaseDevice(
        {
          stockStatus: DeviceStockStatusEnum.Awaiting,
        },
        device.imei.toString()
      ).then((res) => {
        if (res === "OK") {
          devicesDispatch(
            DevicesActions(DevicesTypes.UpdateDeviceByID, {
              device: {
                ...device,
                stockStatus: DeviceStockStatusEnum.Awaiting,
              },
            })
          )
        }
      })
    }
  }, [installationSteps, device])

  const onSaveInstallationStep = async (
    screen: InstallationScreenEnum,
    data: InstallationStep,
    apiSave: Promise<any> | Promise<any>[] | null,
    options
  ) => {
    try {
      if (device?.imei) {
        if (options?.autoSave) {
          await saveInstallationStepByImei(device?.imei, screen, data)
          const steps = (await getInstallationStepsByImei(
            device?.imei
          )) as InstallationStep[]
          dispatch(
            InstallationActions(InstallationTypes.SetInstallationSteps, {
              steps,
            })
          )
        } else {
          const res = await saveInstallationStepByImei(
            device?.imei,
            screen,
            data
          )
          const steps = (await getInstallationStepsByImei(
            device?.imei
          )) as InstallationStep[]
          if (res === "OK") {
            const isFinished = !steps.find((step) =>
              [
                InstallationStepStateEnum.NotStarted,
                InstallationStepStateEnum.Incomplete,
              ].includes(step.stepState)
            )
            if (apiSave) {
              const results = await apiSave
              if (!results.map((x) => x.result).some((res) => res !== "OK")) {
                isFinished
                  ? cogoToast.success("Montering afsluttet 🎉")
                  : cogoToast.success("Oplysninger gemt")
                setCurrentScreen(InstallationScreenEnum.Tasks)
              }
            } else {
              isFinished
                ? cogoToast.success("Montering afsluttet 🎉")
                : cogoToast.success("Oplysninger gemt")
              setCurrentScreen(InstallationScreenEnum.Tasks)
            }
            await getAccountApiVehicles(account.id)
            dispatch(
              InstallationActions(InstallationTypes.SetInstallationSteps, {
                steps,
              })
            )
          }
        }
      }
    } catch (error) {
      cogoToast.error("Kunne ikke gemme oplysningerne.")
      throw new Error(error)
    }
  }

  const renderFlow = () => {
    const screenSet = [
      {
        key: "tasks",
        children: (
          <Tasks
            onSelectScreen={(screen: InstallationScreenEnum) =>
              setCurrentScreen(screen)
            }
          />
        ),
        wrapper: (
          <PopOver
            fromRight
            show={currentScreen !== null && toggled}
            zindex={2200}
            selector="#___wrapper"
            tw="absolute shadow-none overflow-hidden"
          />
        ),
      },
    ]

    if (installationSteps && installationSteps.length > 0) {
      screenSet.push(
        {
          key: "deviceCalibration",
          children: <DeviceCalibration onSave={onSaveInstallationStep} />,
          wrapper: (
            <PopOver
              fromRight
              show={currentScreen === InstallationScreenEnum.DeviceCalibration}
              zindex={2300}
              selector="#___wrapper"
              tw="absolute shadow-none overflow-hidden"
            />
          ),
        },
        {
          key: "deviceInformation",
          children: <DeviceInformation onSave={onSaveInstallationStep} />,
          wrapper: (
            <PopOver
              fromRight
              show={currentScreen === InstallationScreenEnum.DeviceInformation}
              zindex={2300}
              selector="#___wrapper"
              tw="absolute shadow-none overflow-hidden"
            />
          ),
        },
        {
          key: "installationVerification",
          children: (
            <InstallationVerification onSave={onSaveInstallationStep} />
          ),
          wrapper: (
            <PopOver
              fromRight
              show={
                currentScreen ===
                InstallationScreenEnum.InstallationVerification
              }
              zindex={2300}
              selector="#___wrapper"
              tw="absolute shadow-none overflow-hidden"
            />
          ),
        }
        /* {
          key: "dataValidation",
          children: <DataValidation />,
          wrapper: (
            <PopOver
              fromRight
              show={
                currentScreen === InstallationScreenEnum.DataValidation &&
                toggled
              }
              zindex={2300}
              selector="#___wrapper"
              tw="absolute shadow-none overflow-hidden"
            />
          ),
        },
        {
          key: "deviceValidation",
          children: <DeviceHealthValidation />,
          wrapper: (
            <PopOver
              fromRight
              show={
                currentScreen ===
                  InstallationScreenEnum.DeviceHealthValidation && toggled
              }
              zindex={2300}
              selector="#___wrapper"
              tw="absolute shadow-none overflow-hidden"
            />
          ),
        }, */
      )
    }

    return <Flow screenSet={screenSet} />
  }

  return <Responsive phone={renderFlow()} />
}

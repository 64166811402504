import React, { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import "twin.macro"
import {
  Button,
  ButtonGroup,
  Form,
  FormField,
  ValidationInput,
} from "@clevertrack/shared"
import { getDefaultValidationMessagesByLang } from "lib/utils/validation"

const StyledPasswordForm = styled(Form)`
  padding-top: 2rem;
`

function PasswordForm({
  title,
  header,
  children,
  onSubmit,
  onCancel,
  ...props
}) {
  const [password, setPassword] = useState()
  const [repeatPassword, setRepeatPassword] = useState()
  const [revalidatePW, setRevalidatePW] = useState(false)
  const [revalidateRepeatPW, setRevalidateRepeatPW] = useState(false)

  function onPasswordChange(e, repeat) {
    if (repeat) {
      setRepeatPassword(e.target.value)
      setRevalidatePW(true)
      setRevalidateRepeatPW(false)
    } else {
      setPassword(e.target.value)
      setRevalidatePW(false)
      setRevalidateRepeatPW(true)
    }
  }
  /*
  useEffect(() => {
    if (password === repeatPassword) {
      const editedUser = { ...user, password }
      dispatch(UserActions(UserTypes.SetUser, { user: editedUser }))
    }
  }, [password, repeatPassword]) */

  const defaultValidationMessages = getDefaultValidationMessagesByLang("da")
  const validationMessages = {
    ...defaultValidationMessages,
    patternMismatch: "De to passwords matcher ikke.",
  }

  return (
    <>
      <StyledPasswordForm
        {...props}
        // onSubmit={(event) => onSubmit(user, event)}
      >
        {title && <h1>{title}</h1>}
        {header && <div>{header}</div>}
        <FormField validationKey="password" label="Nyt password">
          <ValidationInput
            required
            id="password"
            type="password"
            placeholder="Indtast nyt password"
            // onChange={(e) => onPasswordChange(e)}
            pattern={repeatPassword}
            revalidate={revalidatePW}
            validationMessages={validationMessages}
          />
        </FormField>
        <FormField validationKey="repeatPassword" label="Gentag nyt password">
          <ValidationInput
            required
            id="repeatPassword"
            type="password"
            placeholder="Gentag nyt password"
            onChange={(e) => onPasswordChange(e, true)}
            pattern={password}
            revalidate={revalidateRepeatPW}
            validationMessages={validationMessages}
          />
        </FormField>
        {children && children}
        {!children && (
          <>
            <ButtonGroup tw="mt-8 md:hidden" position="between">
              <Button type="button" variant="transparent" onClick={onCancel}>
                Annuller
              </Button>
              <Button
                // onClick={(event) => onSubmit(user, event)}
                variant="primary"
              >
                Bekræft
              </Button>
            </ButtonGroup>
            <ButtonGroup tw="hidden md:(flex mt-8)" position="between">
              <Button type="button" variant="transparent" onClick={onCancel}>
                Annuller
              </Button>
              <Button
                // onClick={(event) => onSubmit(user, event)}
                variant="primary"
              >
                Bekræft
              </Button>
            </ButtonGroup>
          </>
        )}
      </StyledPasswordForm>
    </>
  )
}

export default PasswordForm

PasswordForm.defaultProps = {}
PasswordForm.propTypes = {
  children: PropTypes.node.isRequired,
}

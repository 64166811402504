import React, { useContext } from "react"
import { AccountsContext } from "app/Account/context"
import { MainContainer } from "app/MainContainer"
import { saveAccount } from "services/accounts"
import { AccountForm } from "app/Account/Form"
import { useAccount } from "app/Account/hooks"
import { useFirestoreCompany } from "services/firestore/company"
import { Account, ClientApiAccount } from "app/Account/types"
import cogoToast from "@clevertrackdk/cogo-toast"
import { StyledFormGrid } from "app/Grid/FormGrid"

export const AccountView: React.FC = ({ ...props }) => {
  const {
    state: { account },
  } = useContext(AccountsContext)
  const { saveFirebaseCompany } = useFirestoreCompany()

  useAccount()

  console.log(account)

  const onSaveHandler = async (updatedCompany: Account) => {
    try {
      const [fbRes, apiRes] = await Promise.all([
        saveFirebaseCompany(
          {
            vatNumber:
              updatedCompany.vatNumber === undefined
                ? null
                : updatedCompany.vatNumber,
            name: updatedCompany.name,
            description: updatedCompany.description,
            features: updatedCompany.features ?? {},
            functions: updatedCompany.functions ?? {},
            msisdn:
              updatedCompany.msisdn === undefined
                ? null
                : updatedCompany.msisdn,
            map:
              updatedCompany.mapLatitude &&
              updatedCompany.mapLongitude &&
              updatedCompany.defaultMapZoom
                ? {
                    center: [
                      +updatedCompany.mapLatitude,
                      +updatedCompany.mapLongitude,
                    ],
                    defaultZoom: +updatedCompany.defaultMapZoom,
                  }
                : null,
          },
          updatedCompany.id.toString()
        ),
        saveAccount({
          ...updatedCompany,
          contact_phone: updatedCompany.contactPhone,
        } as ClientApiAccount),
      ])

      if (fbRes === "OK" && apiRes.data.result === "OK") {
        cogoToast.success(`Kontoen '${updatedCompany.name}' blev gemt`)
      }
    } catch (error) {
      cogoToast.error(`Kontoen '${updatedCompany.name}' blev ikke gemt`)
      console.log(error)
    }
  }

  return (
    <MainContainer
      header={
        <>
          <h2>{account?.name}</h2>
        </>
      }
    >
      <StyledFormGrid>
        <div>
          {account && (
            <AccountForm
              account={account}
              onSubmit={onSaveHandler}
              basicTitle="Stamdata"
              settingsTitle="Opsætning"
              saveButtonText="Gem stamdata"
              showSettings
            />
          )}
        </div>
      </StyledFormGrid>
    </MainContainer>
  )
}

import { useContext } from "react"
import { FirebaseContext } from "context/FirebaseProvider"
import {
  doc,
  collection,
  updateDoc,
  serverTimestamp,
  getDocs,
  setDoc,
  getDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore"
import { Account } from "app/Account/types"

export const useFirestoreCompany = () => {
  const { db } = useContext(FirebaseContext)

  const getFirebaseCompanies = async () => {
    if (db) {
      try {
        const firebaseCompanies = collection(db, `companies`)

        const res = await getDocs(firebaseCompanies)

        return res.docs.map((doc) => doc.data())
      } catch (error) {
        throw new Error(error)
      }
    }
  }

  const createFirebaseCompany = async (args: Account, companyID: string) => {
    if (db) {
      try {
        await setDoc(doc(db, "companies", companyID), args)

        return "OK"
      } catch (error) {
        throw new Error(error)
      }
    }
  }

  const saveFirebaseCompany = async (args: Account, companyID: string) => {
    if (db) {
      const firebaseCompany = doc(db, `companies`, companyID)
      try {
        const res = await updateDoc(firebaseCompany, {
          ...args,
          updated: serverTimestamp(),
        })

        return "OK"
      } catch (error) {
        const findDoc = await getDoc(firebaseCompany)

        if (findDoc.exists()) {
          throw new Error(error)
        } else {
          createFirebaseCompany(args, companyID)
        }
      }
    }
  }

  const assignAccountsToFirebaseCompany = async (
    accounts,
    companyID: string
  ) => {
    if (db) {
      const firebaseCompany = doc(db, `companies`, companyID)
      try {
        const res = await updateDoc(firebaseCompany, {
          assignedAccounts: arrayUnion(...accounts),
          updated: serverTimestamp(),
        })

        return "OK"
      } catch (error) {
        console.log(error)
        throw new Error(error)
      }
    }
  }

  const unassignAccountsToFirebaseCompany = async (
    accounts,
    companyID: string
  ) => {
    if (db) {
      const firebaseCompany = doc(db, `companies`, companyID)
      try {
        const res = await updateDoc(firebaseCompany, {
          assignedAccounts: arrayRemove(...accounts),
          updated: serverTimestamp(),
        })

        return "OK"
      } catch (error) {
        console.log(error)
        throw new Error(error)
      }
    }
  }

  return {
    createFirebaseCompany,
    saveFirebaseCompany,
    getFirebaseCompanies,
    assignAccountsToFirebaseCompany,
    unassignAccountsToFirebaseCompany,
  }
}

import { useCallback, useContext, useEffect, useState } from "react"
import { AppContext } from "context"
import { GlobalAppActions, GlobalAppTypes } from "context/App/actions"

export const usePagination = (initialPage?: number) => {
  const {
    state: { paginationPage },
    dispatch,
  } = useContext(AppContext)

  const setCurrentPage = useCallback((p) => {
    dispatch(
      GlobalAppActions(GlobalAppTypes.SetCurrentPaginationPage, { page: p })
    )
  }, [])

  useEffect(() => {
    if (initialPage)
      dispatch(
        GlobalAppActions(GlobalAppTypes.SetCurrentPaginationPage, {
          page: initialPage,
        })
      )

    return () => {
      dispatch(
        GlobalAppActions(GlobalAppTypes.SetCurrentPaginationPage, {
          page: null,
        })
      )
    }
  }, [initialPage])

  return { currentPage: paginationPage, setCurrentPage }
}

import { ApiVehicle } from "app/Devices/types"

// Installation Flow Screens
export enum InstallationScreenEnum {
  Tasks = "Tasks",
  DeviceInformation = "DeviceInformation",
  DataValidation = "DataValidation",
  DeviceHealthValidation = "DeviceHealthValidation",
  DeviceCalibration = "DeviceCalibration",
  InstallationVerification = "InstallationVerification",
}

export enum APIVehicleKindEnum {
  Van = "Varebil",
  Tractor = "Traktor",
  Trailer = "Påhængsvogn",
  Machine = "Motorredskab",
  Truck = "Lastbil",
  SemiTrailer = "Sættevogn",
  Car = "Personbil",
}

export enum InstallationStepStateEnum {
  Completed = "Completed",
  Incomplete = "Incomplete",
  NotStarted = "Not started",
}

export const NoneMachineVehicleTypes = [
  APIVehicleKindEnum.Car,
  APIVehicleKindEnum.Truck,
  APIVehicleKindEnum.Van,
  APIVehicleKindEnum.SemiTrailer,
  APIVehicleKindEnum.Trailer,
]

export type DeviceInformationModel = {
  legacyVehicleName?: string
  vehicleName: string
  vehicleDescription: string
  vehicleNumber?: string | null
  vehicleRegistrationNumber?: string | null
  vehicleLicensePlatePhoto?: string | null
  vehicleLicensePlatePhotoPath?: string | null
  vehicleFrameNumber: string | null
  vehicleTypePlatePhoto: string | null
  vehicleTypePlatePhotoPath: string | null
  vehicleBrand: string | null
  vehicleModel: string | null
  vehicleYear: string | null
  vehicleUniqueness: string | null
  // vehicleGroup: string[]
}

export type DataValidationModel = {
  programNumber: number
  totalEngineHoursAvailable: boolean
  totalDistanceAvailable: boolean
  totalFuelAvailable: boolean
}

export type DeviceHealthValidationModel = {
  devicePower: boolean
  deviceIgnition: boolean
  deviceSatcountOK: boolean
  deviceInternalBatteryOK: boolean
}

export type DeviceCalibrationModel = {
  deviceConfiguration: number | null
  deviceIdleConfiguration: number | null
  totalEngineHours: number
  totalDistance: number
  noCalibrationDataNote: string | null
  lowerIdleRPM: number
  zeroIdleAIN1: number
  lowerIdleAIN1: number
  engineLoadLow: number
}

export enum ExtendedBoolean {
  YES = "Yes",
  NO = "No",
  UNKNOWN = "Unknown",
}

export enum MainSwitchBypassBoolean {
  YES = "Yes",
  NO = "No",
  POWERBYPASSED = "Power Bypassed",
}

export type InstallationVerificationModel = {
  deviceCANDataReaderCount?: number | null
  deviceCANDataReaderPhoto?: string
  deviceCANDataReaderPhotoPath?: string
  deviceCANDataReaderNote?: string
  deviceCANHighColor?: string
  deviceCANLowColor?: string
  deviceCAN2DataReaderPhoto?: string
  deviceCAN2DataReaderPhotoPath?: string
  deviceCAN2DataReaderNote?: string
  deviceCAN2HighColor?: string
  deviceCAN2LowColor?: string
  deviceMountingPhoto: string
  deviceMountingPhotoPath: string
  deviceMountingNote: string
  devicePlacementPhoto: string
  devicePlacementPhotoPath: string
  devicePlacementNote: string
  vehicleFrontPhoto: string
  vehicleFrontPhotoPath: string
  vehicleFrontNote: string
  isMainSwitchPresent: ExtendedBoolean | null
  isMainSwitchControlledByGround?: boolean | null // If true, there's a risk the tracker will register false ignition
  isDeviceMountedWithIgnitionWireRelay?: boolean | null // A relay should be mounted, to prevent false ignition, if the installer finds it necessary
  isDeviceBypassingMainSwitch?: MainSwitchBypassBoolean | null // If true, a reason should be provided for bypassing the main switch
  autoOffMounted?: boolean | null
  autoOffRelayMountPhoto?: string
  autoOffRelayMountPhotoPath?: string
  autoOffRelayMountNote?: string
}

export type InstallationStepDataModel =
  | DeviceInformationModel
  | DataValidationModel
  | DeviceHealthValidationModel
  | DeviceCalibrationModel
  | InstallationVerificationModel

export type InstallationStepDataModelProps = DeviceInformationModel &
  DataValidationModel &
  DeviceHealthValidationModel &
  DeviceCalibrationModel &
  InstallationVerificationModel

export type InstallationStep = {
  stepID: InstallationScreenEnum
  stepState: InstallationStepStateEnum
  data: InstallationStepDataModel
  optionalDataProps: (keyof InstallationStepDataModelProps)[]
}

export type InstallationStepType = {
  onSave: <T>(
    screen: InstallationScreenEnum,
    data: InstallationStep,
    apiSave: Promise<T> | null,
    options?: {
      autoSave: boolean
    }
  ) => Promise<void>
}

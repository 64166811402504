import styled from "styled-components"
import tw from "twin.macro"

export const StyledFormGrid = styled.section`
  ${(props) => props.theme.media.tablet_landscape_up`
    ${tw`p-8 overflow-hidden`}
    height: calc(100% - 6rem);
    display: grid;
    grid-template-columns: 80rem 1fr;
    column-gap: 2rem;

    .widget {
      ${tw`shadow-lg`}
    }
  `}
`

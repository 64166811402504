import React, { useContext, useMemo } from "react"
import styled from "styled-components"
import tw from "twin.macro"

import { AccountsContext } from "app/Account/context"
import { ApiAccountTypeEnum } from "app/Account/types"
import { DevicesContext } from "app/Devices/context"
import { DonutMetric } from "app/KPI/Donut"
import { InstallerHome } from "./Installer"
import { UserContext } from "app/User/context"
import { UserTypeEnum } from "app/User/types"

const StyledAccounts = styled.section`
  ${tw`flex items-center space-y-0 space-x-8`}
  line-height: 1;

  h2 {
    margin: 0;
    font-family: ${(props) => props.theme.fontStacks.light};
    line-height: 1;
    ${tw`m-0 text-2xl`}
  }

  .card {
    padding-right: 6rem;
  }
`

export const Home: React.FC = ({ ...props }) => {
  const {
    state: { accounts, account },
  } = useContext(AccountsContext)
  const {
    state: { devices },
  } = useContext(DevicesContext)
  const {
    state: { user },
  } = useContext(UserContext)

  const clientAccounts = useMemo(
    () => accounts.filter((x) => x.description === ApiAccountTypeEnum.Client),
    [accounts]
  )

  const pilotAccounts = useMemo(
    () => accounts.filter((x) => x.description === ApiAccountTypeEnum.Pilot),
    [accounts]
  )

  const retailerAccounts = useMemo(
    () => accounts.filter((x) => x.description === ApiAccountTypeEnum.Retailer),
    [accounts]
  )

  if (!account) return null

  if (
    account?.description === ApiAccountTypeEnum.Installer ||
    user?.userTypes?.includes(UserTypeEnum.FITTER)
  )
    return <InstallerHome />

  return (
    <div tw="pt-8 pl-8 h-lvh">
      <h2>Konti</h2>
      <StyledAccounts>
        <DonutMetric
          title="Kunder"
          segment={clientAccounts}
          source={accounts}
          id="clients"
          chartStyle={tw`fill-brand-green-50 stroke-brand-green-50`}
        />
        <DonutMetric
          title="Piloter"
          segment={pilotAccounts}
          source={accounts}
          id="pilots"
          chartStyle={tw`fill-brand-yellow-200 stroke-brand-yellow-200`}
        />
        <DonutMetric
          title="Forhandlere"
          segment={retailerAccounts}
          source={accounts}
          id="retailers"
          chartStyle={tw`fill-brand-blue-200 stroke-brand-blue-200`}
        />
      </StyledAccounts>
    </div>
  )
}

import React, { useContext, useMemo } from "react"
import { UserContext } from "app/User/context"
import { UserTypeEnum, UserTypes } from "app/User/types"

type FeatureComponentType = {
  allowedUserRoles: UserTypeEnum[]
  allowedUserFlags: (keyof UserTypes)[]
}

export const FeatureComponent: React.FC<FeatureComponentType> = ({
  allowedUserRoles = [],
  allowedUserFlags = [],
  children,
  ...props
}) => {
  const {
    state: { user },
  } = useContext(UserContext)

  const hasAccess = useMemo(() => {
    if (user) {
      if (user?.is_cms_admin) return true
      if (allowedUserRoles.some((x) => user?.userTypes?.includes(x)))
        return true
      if (allowedUserFlags.find((key) => user[key] === 1 || user[key] === true))
        return true
      return false
    }
    return false
  }, [user, allowedUserRoles, allowedUserFlags])

  return hasAccess ? <>{children}</> : null
}

// Types of users
export enum UserTypeEnum {
  CMSADMIN = "Cms Admin",
  ADMIN = "Admin",
  FITTER = "Montør",
  RETAILER = "Forhandler",
  USER = "User",
}

// User
export type BasicUser = {
  id: number
  account_id: number
  account: string
  active: number
  username: string
  email: string
  firstName: string
  lastName: string
  phoneNumber: string
  company: string
  companyID: number // Account the user belongs to
  avatarURL?: string
  is_admin?: number
  token: string
  is_token_whitelisted?: number
  language: string
  userTypes?: UserTypeEnum[]
}

export type AdminUser = BasicUser & {
  is_cms_admin?: number
}

// An account admin always has access to all features assigned to their account.
// An account admin can create account users, that can access a subset of features they assign to them.
export type FitterUser = BasicUser & {
  isAccountAdmin?: boolean
  feature: string[]
}

export type RetailerUser = BasicUser & {
  isAccountAdmin?: boolean
  feature: string[] // Retailers have features that are available in the Hub
}

export type UserTypes = BasicUser & AdminUser & FitterUser & RetailerUser

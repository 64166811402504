import React, { useReducer, createContext } from "react"
import { SearchActionMap, SearchTypes } from "./actions"

/**
 * Set initial state and create the context. The Provider can be wrapped around any component up the tree
 * This approach enables us to keep things really modular
 */
type InitialSearchStateType = {
  results: any[]
  suggestions: any[]
  query: string
}

const initialState = {
  results: [],
  suggestions: [],
  query: "",
}

const SearchContext = createContext<{
  state: InitialSearchStateType
  dispatch: React.Dispatch<any>
}>({ state: initialState, dispatch: () => null })

const searchReducer = (
  state: InitialSearchStateType,
  action: SearchActionMap
) => {
  switch (action.type) {
    case SearchTypes.SetResults:
      return {
        ...state,
        results: action.payload.results,
      }
    case SearchTypes.SetQuery:
      return {
        ...state,
        query: action.payload.query,
      }
    case SearchTypes.SetSuggestions:
      return {
        ...state,
        suggestions: action.payload.suggestions,
      }
    case SearchTypes.ResetSearch:
      return {
        results: [],
        suggestions: [],
        query: "",
      }
    default:
      return state
  }
}

const SearchProvider = ({ children }) => {
  const [state, dispatch] = useReducer(searchReducer, initialState)

  return (
    <SearchContext.Provider value={{ state, dispatch }}>
      {children}
    </SearchContext.Provider>
  )
}

export { SearchContext, SearchProvider }

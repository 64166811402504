import React, { useContext, useEffect, useMemo, useState } from "react"
import tw from "twin.macro"
import { InstallationContext } from "../context"
import { FlowHeader, ScreenHeader } from "./components"
import styled from "styled-components"
import {
  InstallationScreenEnum,
  InstallationStep,
  InstallationStepStateEnum,
} from "../types"
import { getDeviceByImei } from "services/devices"
import { Button, ButtonGroup, Icon, Tag } from "@clevertrack/shared"
import { IconSizeEnum } from "lib/Icon"
import { useInstallation } from "../hooks"
import { DeviceTypeCategoryEnum } from "app/Devices/types"
import { InstallationActions, InstallationTypes } from "../actions"

const InstallationStepTheme = {
  [InstallationStepStateEnum.NotStarted]: tw`flex items-center`,
  [InstallationStepStateEnum.Incomplete]: tw`flex items-center text-yellow-500`,
  [InstallationStepStateEnum.Completed]: tw`flex items-center text-green-500`,
}

const StyledWrapper = styled.div`
  ${tw`w-full h-full bg-white`}
  display: grid;
  grid-template-rows: auto auto auto 1fr auto;
`

const StyledTaskList = styled.section``

type TasksType = {
  onSelectScreen: (screen: InstallationScreenEnum) => void
}

export const Tasks: React.FC<TasksType> = ({ onSelectScreen, ...props }) => {
  const {
    dispatch,
    state: { device, installationSteps, fotaDevice },
  } = useContext(InstallationContext)
  const { deviceTypeCategory, getFotaDevicByIMEI } = useInstallation()
  const [completedSteps, totalSteps] = useMemo(() => {
    return installationSteps
      ? [
          installationSteps.filter(
            (step) => step.stepState === InstallationStepStateEnum.Completed
          ).length,
          installationSteps.length,
        ]
      : []
  }, [installationSteps, device])

  const onSelectNewDeviceHandler = () => {
    dispatch(InstallationActions(InstallationTypes.ClearDevice))
  }

  useEffect(() => {
    if (device?.imei && fotaDevice === null) {
      getFotaDevicByIMEI(device?.imei)
    }
  }, [device, fotaDevice])

  const renderStepStateIcon = (stepState: InstallationStepStateEnum | null) => {
    switch (stepState) {
      case InstallationStepStateEnum.Completed:
        return <Icon tw="w-5 h-5 ml-2" icon="check" />
      case InstallationStepStateEnum.Incomplete:
        return <Icon tw="w-5 h-5 ml-2" icon="exclamation-triangle" />
      default:
        return null
    }
  }

  return !installationSteps ? null : (
    <StyledWrapper>
      <FlowHeader includeBackLink />
      <ScreenHeader>
        {completedSteps} /{" "}
        {[
          DeviceTypeCategoryEnum.Beacon,
          DeviceTypeCategoryEnum.GPSTrackerWithBattery,
        ].includes(deviceTypeCategory)
          ? `2 `
          : `3 `}
        trin fuldført
      </ScreenHeader>
      <StyledTaskList>
        <ul tw="m-0 list-none p-0 border border-solid border-0 border-t border-brand-gray-brand">
          <li
            tw="px-8 py-6 border border-solid border-0 border-b border-brand-gray-brand"
            onClick={() =>
              onSelectScreen(InstallationScreenEnum.InstallationVerification)
            }
          >
            <span
              css={
                InstallationStepTheme[
                  installationSteps.find(
                    (step) =>
                      step.stepID ===
                      InstallationScreenEnum.InstallationVerification
                  )?.stepState
                ]
              }
            >
              <span tw="text-2xl">Montering</span>
              {renderStepStateIcon(
                installationSteps.find(
                  (step) =>
                    step.stepID ===
                    InstallationScreenEnum.InstallationVerification
                )?.stepState
              )}
            </span>
            <span tw="block mt-2 text-xl opacity-60">
              Monteringsinformation og billeder
            </span>
          </li>
          {/* <li
            tw="px-8 py-6 border border-solid border-0 border-b border-brand-gray-brand"
            onClick={() =>
              onSelectScreen(InstallationScreenEnum.DataValidation)
            }
          >
            {steps.find(
              (step) => step.stepID === InstallationScreenEnum.DataValidation
            )?.stepCompleted ? (
              <span tw="flex items-center text-green-500">
                <span tw="text-2xl">Datavalidering</span>
                <Icon tw="w-5 h-5 ml-2" icon="check" />
              </span>
            ) : (
              <span tw="text-2xl flex items-center">Datavalidering</span>
            )}
            <span tw="block mt-2 text-xl opacity-60">
              Valg af programnr. og validering af evt. CAN-data
            </span>
          </li>
          <li
            tw="px-8 py-6 border border-solid border-0 border-b border-brand-gray-brand"
            onClick={() =>
              onSelectScreen(InstallationScreenEnum.DeviceHealthValidation)
            }
          >
            {steps.find(
              (step) =>
                step.stepID === InstallationScreenEnum.DeviceHealthValidation
            )?.stepCompleted ? (
              <span tw="flex items-center text-green-500">
                <span tw="text-2xl">Monteringsvalidering</span>
                <Icon tw="w-5 h-5 ml-2" icon="check" />
              </span>
            ) : (
              <span tw="text-2xl flex items-center">Monteringsvalidering</span>
            )}
            <span tw="block mt-2 text-xl opacity-60">
              Valider enhedens forbindelse og helbred
            </span>
          </li> */}
          {![
            DeviceTypeCategoryEnum.Beacon,
            DeviceTypeCategoryEnum.GPSTrackerWithBattery,
          ].includes(deviceTypeCategory) && (
            <li
              tw="px-8 py-6 border border-solid border-0 border-b border-brand-gray-brand"
              onClick={() =>
                onSelectScreen(InstallationScreenEnum.DeviceCalibration)
              }
            >
              <span
                css={
                  InstallationStepTheme[
                    installationSteps.find(
                      (step) =>
                        step.stepID === InstallationScreenEnum.DeviceCalibration
                    )?.stepState
                  ]
                }
              >
                <span tw="text-2xl">Kalibrering</span>
                {renderStepStateIcon(
                  installationSteps.find(
                    (step) =>
                      step.stepID === InstallationScreenEnum.DeviceCalibration
                  )?.stepState
                )}
              </span>
              <span tw="block mt-2 text-xl opacity-60">
                Motortimer, km-stand og kalibrering af tomgang
              </span>
            </li>
          )}
          <li
            tw="px-8 py-6 border border-solid border-0 border-b border-brand-gray-brand"
            onClick={() =>
              onSelectScreen(InstallationScreenEnum.DeviceInformation)
            }
          >
            <span
              css={
                InstallationStepTheme[
                  installationSteps.find(
                    (step) =>
                      step.stepID === InstallationScreenEnum.DeviceInformation
                  )?.stepState
                ]
              }
            >
              <span tw="text-2xl">Stamdata</span>
              {renderStepStateIcon(
                installationSteps.find(
                  (step) =>
                    step.stepID === InstallationScreenEnum.DeviceInformation
                )?.stepState
              )}
            </span>
            <span tw="block mt-2 text-xl opacity-60">
              Mærke, model, årgang, stel- og reg. nr.
            </span>
          </li>
        </ul>
      </StyledTaskList>
      <ButtonGroup sticky="bottom" position="around" tw="px-8 bottom-0">
        <Button type="button" variant="cancel">
          <span
            tw="flex items-center"
            onClick={() => onSelectNewDeviceHandler()}
          >
            <Icon icon="chevron-left" tw="w-4 h-4 mr-2" />
            <span tw="text-xl font-normal">Vælg en anden enhed</span>
          </span>
        </Button>
        {/* <Button type="button" variant="primary">
            Start gennemgang
          </Button> */}
      </ButtonGroup>
    </StyledWrapper>
  )
}

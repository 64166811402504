import React, { useContext } from "react"
import { Search } from ".."
import "twin.macro"
import { AccountsContext } from "app/Account/context"
import { DevicesContext } from "app/Devices/context"
import { freetextSearch } from "../helper"
import { SearchContext } from "../context"

export const GlobalSearch: React.FC = ({ ...props }) => {
  const {
    state: { accounts },
  } = useContext(AccountsContext)
  const {
    state: { devices },
  } = useContext(DevicesContext)
  const {
    state: { query },
  } = useContext(SearchContext)

  const dataset = freetextSearch([...accounts, ...devices], {
    threshold: 0.25,
    location: 0,
    distance: 81,
    fieldNormWeight: query.length > 1 ? 0.5 : 1,
    ignoreLocation: query.length > 1,
    includeScore: true,
    keys: ["name", "note"],
  })

  return (
    <Search
      placeholder="Søg efter en enhed, konto eller bruger"
      dataset={dataset}
      {...props}
    ></Search>
  )
}

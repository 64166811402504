import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import tw from "twin.macro"

const StyledNavItem = styled.li`
  ${tw`w-full`}
  a {
    display: grid;
    grid-template-columns: 2rem 1fr;
    grid-column-gap: 1.5rem;
    ${tw`py-4 px-8 w-full`}

    &.active {
      position: relative;
      ${tw`text-white`}
      span, svg {
        z-index: 30;
        position: relative;
      }
      &:after {
        width: 90%;
        left: 5%;
        height: 4rem;
        z-index: 20;
        pointer-events: none;
        content: "";
        position: absolute;
        // opacity: 0.1;
        ${tw`bg-brand-500`};
      }
    }

    &.icon-only {
      padding-left: 2rem;
      grid-column-gap: 0;
      grid-template-columns: 2rem;
    }

    > span {
      display: block;
      line-height: 1;
    }

    svg {
      height: 1.6rem;
      width: 1.6rem;
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    display: flex;

    a {
      grid-row-gap: 1rem;
      font-size: 1.4rem;
      svg {
        margin: 0 auto;
        height: 1.6rem;
        width: 1.6rem;
      }
    }
  `}

  ${(props) => props.theme.media.desktop_up`
    a {
      grid-row-gap: 1rem;
      font-size: 1.4rem;

      svg {
        margin: auto;
        height: 1.6rem;
        width: 1.6rem;
      }
    }
  `}
`

function NavItem({ children, ...props }) {
  return <StyledNavItem {...props}>{children}</StyledNavItem>
}

export default NavItem

NavItem.defaultProps = {}
NavItem.propTypes = {
  children: PropTypes.node.isRequired,
}

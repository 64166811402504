import React, { useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import "twin.macro"
import { UserContext } from "./context"
import UserName from "./Name"
import { Responsive } from "@clevertrack/shared"
import tw from "twin.macro"
import { AccountsContext } from "app/Account/context"
import { useAccount } from "app/Account/hooks"

const StyledUser = styled.li<{ withAvatar: boolean }>`
  margin-top: auto;
  display: grid;
  align-items: center;
  ${tw`w-full`}
  grid-template-rows: ${(props) =>
    props.withAvatar ? `8rem 1fr 1fr` : `1fr 1fr`};
  grid-row-gap: 1rem;
  padding-top: ${(props) => (props.withAvatar ? `0` : `2rem`)};
`

const StyledAvatar = styled.div`
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  overflow: hidden;
  ${tw`text-brand-gray-dark`}
  font-family: ${(props) => props.theme.fontStacks.bold};
  margin: 0 auto 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  background: ${(props) => props.theme.colors.grayLight};
  margin-bottom: 1rem;
  text-transform: uppercase;
  border: 4px solid #fff;

  img {
    filter: grayscale(1);
  }
`

function User({ children, ...props }) {
  const {
    state: { user },
  } = useContext(UserContext)

  useAccount(user?.companyID)

  if (!user) return null
  return (
    <StyledUser withAvatar={user.firstName} {...props}>
      {user.avatarURL ? (
        <StyledAvatar>
          <img src={user.avatarURL} />
        </StyledAvatar>
      ) : (
        user.firstName && (
          <StyledAvatar>{user.firstName.split("")[0]}</StyledAvatar>
        )
      )}
      <UserName {...user} />
      <Responsive tabletLandscape={<></>} />
    </StyledUser>
  )
}

export default User

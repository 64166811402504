import React from "react"
import styled from "styled-components"
import tw from "twin.macro"

const StyledContainer = styled.div`
  ${tw`w-full h-full overflow-y-scroll`}
`

export const ContentContainer: React.FC = ({ children, ...props }) => {
  return <StyledContainer {...props}>{children}</StyledContainer>
}

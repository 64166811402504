import React from "react"
import { Button, Icon } from "@clevertrack/shared"
import "twin.macro"
import { FileUpload, MimeTypes } from "app/FileUpload"
import { IconSizeEnum } from "lib/Icon"
import { useFirebaseStorage } from "services/storage"
import cogoToast from "@clevertrackdk/cogo-toast"

type PhotoUploadType = {
  photoPropertyKey: string
  photoPropertyValue?: string | null
  photoFilename: string
  photoPath?: string | null
  uploadLabel: string
  uploadDescription: string
  onUpdateModel: (key: any, value: string | null) => void
  onAcceptFilesHandler: (any, filename: string, key: string) => void
}

export const PhotoUpload: React.FC<PhotoUploadType> = ({
  photoPropertyKey,
  photoPropertyValue,
  photoFilename,
  photoPath,
  uploadLabel,
  uploadDescription,
  onAcceptFilesHandler,
  onUpdateModel,
  ...props
}) => {
  const { deleteFileByPath } = useFirebaseStorage()

  const onDeleteHandler = async () => {
    try {
      if (photoPath) {
        const result = await deleteFileByPath(photoPath)
        onUpdateModel(photoPropertyKey, null)
      } else {
      }
    } catch (e) {
      cogoToast.error(`Kunne ikke slette billedet: ${e}`)
    }
  }

  return (
    <>
      {photoPropertyValue && (
        <div tw="w-full h-auto mt-8 relative">
          <Button
            tw="absolute top-4 right-4 z-40 p-2 pl-4"
            onClick={onDeleteHandler}
            variant="danger"
            size="sm"
            type="button"
          >
            <span>Slet</span>
            <Icon icon="close" size={IconSizeEnum.SM} />
          </Button>
          <img tw="w-full h-auto relative z-30" src={photoPropertyValue} />
        </div>
      )}
      {!photoPropertyValue && (
        <FileUpload
          uploadOnDrop
          onAccept={(files) =>
            onAcceptFilesHandler(files, photoFilename, photoPropertyKey)
          }
          allowedMimeTypes={[MimeTypes.JPEG]}
          uploadLabel={uploadLabel}
          uploadDescription={uploadDescription}
          hideAllowedMimeTypes
        />
      )}
    </>
  )
}
